let timeinterval: any

const timer = (endTime: Number): Number => {
  clearInterval(timeinterval)
  const localTimer = localStorage.getItem('timer')
  let timer: any = localTimer || endTime

  timeinterval = setInterval(function () {
    const next = --timer
    localStorage.setItem('timer', next.toString())
    if (timer <= 0) {
      clearInterval(timeinterval)
      localStorage.removeItem('timer')
    }
  }, 1000)

  return Number(timer)
}

export default timer
