import {
  AccountActionTypes,
  UserNameActionTypes,
  SET_ACCOUNT,
  SET_USER_NAME,
  SET_WALLET,
  AccountState,
  WalletActionTypes
} from "./types";

const initialState: AccountState = {
  account: "",
  user_name: "",
  wallet: "",
};

export function accountReducer(
  state = initialState,
  action: AccountActionTypes | UserNameActionTypes | WalletActionTypes
): AccountState {
  switch (action.type) {
    case SET_ACCOUNT:
      return {
        ...state,
        account: action.payload,
      };

    case SET_USER_NAME:
      return {
        ...state,
        user_name: action.payload,
      };

    case SET_WALLET:
      return {
        ...state,
        wallet: action.payload,
      };

    default:
      return state;
  }
}
