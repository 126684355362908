import {
  IBalance,
  ITransactions,
  ITermPart,
  IPart,
  ITFuelCardsTransactions,
} from '../interfaces'
import {
  FUELS,
  FUELS_ENUM,
  UNDISTRIBUTED_TITLE,
  EMPTY_GUID,
  TRANSACTION_TYPES,
  TOTAL_ACCOUNT,
} from '../constants'

export const balancesAdapter = (data: Array<any>, isAccounts: boolean) => {
  const walletBalance: any = {}
  const result: Array<IBalance> = data.map((item: any, i: number) => {
    const balances: any = {}
    const isUndistributedBalance = item['Аккаунт'] === EMPTY_GUID
    if (!isAccounts) {
      walletBalance[item['Номенклатура']] = {
        count: item['Количество'],
        parts: item['Партии'],
      }
    } else {
      item['Балансы'].map((balance: any) => {
        balances[balance['Номенклатура']] = {
          count: balance['Количество'],
          parts: balance['Партии'],
        }
      })
    }

    return {
      key: `balance_${i}`,
      accountName: !isUndistributedBalance
        ? item['АккаунтНаименование']
        : UNDISTRIBUTED_TITLE,
      userPhone: !isUndistributedBalance
        ? item['АккаунтНомерТелефона']
        : EMPTY_GUID,
      account: item['Аккаунт'],
      alias: !isUndistributedBalance ? item['Псевдоним'] : UNDISTRIBUTED_TITLE,
      status: item['Включен'],
      block: true,
      a100_Mustang: {
        count: balances[FUELS_ENUM.a100_Mustang]?.count,
        parts: balances[FUELS_ENUM.a100_Mustang]?.parts,
      },
      a95_Mustang: {
        count: balances[FUELS_ENUM.a95_Mustang]?.count,
        parts: balances[FUELS_ENUM.a95_Mustang]?.parts,
      },
      a95: {
        count: balances[FUELS_ENUM.a95]?.count,
        parts: balances[FUELS_ENUM.a95]?.parts,
      },
      a92: {
        count: balances[FUELS_ENUM.a92]?.count,
        parts: balances[FUELS_ENUM.a92]?.parts,
      },
      dp_Mustang_Plus: {
        count: balances[FUELS_ENUM.dp_Mustang_Plus]?.count,
        parts: balances[FUELS_ENUM.dp_Mustang_Plus]?.parts,
      },
      dp: {
        count: balances[FUELS_ENUM.dp]?.count,
        parts: balances[FUELS_ENUM.dp]?.parts,
      },
      gas: {
        count: balances[FUELS_ENUM.gas]?.count,
        parts: balances[FUELS_ENUM.gas]?.parts,
      },
      edit: false,
      status_connection: item['СостояниеПодключение'],
    }
  })

  return isAccounts
    ? result
    : [
        {
          alias: 'Всього по рахунку',
          key: TOTAL_ACCOUNT,
          status: false,
          a100_Mustang: {
            count: walletBalance[FUELS_ENUM.a100_Mustang]?.count,
            parts: walletBalance[FUELS_ENUM.a100_Mustang]?.parts,
          },
          a95_Mustang: {
            count: walletBalance[FUELS_ENUM.a95_Mustang]?.count,
            parts: walletBalance[FUELS_ENUM.a95_Mustang]?.parts,
          },
          a95: {
            count: walletBalance[FUELS_ENUM.a95]?.count,
            parts: walletBalance[FUELS_ENUM.a95]?.parts,
          },
          a92: {
            count: walletBalance[FUELS_ENUM.a92]?.count,
            parts: walletBalance[FUELS_ENUM.a92]?.parts,
          },
          dp_Mustang_Plus: {
            count: walletBalance[FUELS_ENUM.dp_Mustang_Plus]?.count,
            parts: walletBalance[FUELS_ENUM.dp_Mustang_Plus]?.parts,
          },
          dp: {
            count: walletBalance[FUELS_ENUM.dp]?.count,
            parts: walletBalance[FUELS_ENUM.dp]?.parts,
          },
          gas: {
            count: walletBalance[FUELS_ENUM.gas]?.count,
            parts: walletBalance[FUELS_ENUM.gas]?.parts,
          },
        },
      ]
}

export const transactionsAdapter = (data: Array<any>) => {
  const result: Array<ITransactions> = data.map((item: any, i: number) => {
    const doc = item['Значение']
    return {
      key: `row_tr${i}`,
      dateT: item['Период'],
      fuelType: doc['НоменклатураНаименование'],
      amount: doc['Количество'],
      type: TRANSACTION_TYPES[item['Тип'] as keyof typeof TRANSACTION_TYPES],
      user: doc['Аккаунт'],
      gasStation: doc['АЗС'],
      part: doc['Партия'],
    }
  })

  return result
}

export const partsAdapter = (data: Array<any>) => {
  const allParts: Array<ITermPart> = []
  data.map((item: any) => {
    item['Партии'].map((part: IPart, index: number) => {
      allParts.push({
        key: `fuel_${item['Номенклатура']}part_${index}`,
        fuel: item['Номенклатура'],
        fuel_title: FUELS.find((fuel) => fuel.value === item['Номенклатура'])
          ?.name,
        document: part['ДокументПартии'],
        date: part['ДатаПополнения'],
        count: part['КоличествоПополнения'],
        balance: part['Количество'],
        dateEnd: {
          date: part['СрокДействия'],
          term_expires: part['СрокДействияИстикает'],
          block: part['Заблокирована'],
        },
        part: part['Партия'],

        price: part['Цена'],
      })
    })
  })
  return allParts
}

export const transactionsFuelCardAdapter = (data: Array<any>) => {
  const result: Array<ITFuelCardsTransactions> = data.map(
    (item: any, i: number) => {
      return {
        key: `row_tr${i}`,
        date: item['Период'],
        userAlias: item['ПредставлениеАккаунта'],
        fuelType: item['Номенклатура'],
        part: item['ДокументПартии'],
        user: item['Аккаунт'],
        amount: item['Количество'],
        type: item['ТипТранзакции'],
        gasStation: item['АЗК'],
      }
    }
  )

  return result
}
