import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { routes, renderRoute } from './routes'
import './App.less'
import MainLayout from './common/layout/MainLayout'
import { RootState } from './store'

const App = () => {
  const [initialized, setInitialized] = useState(false)
  const { isAuth } = useSelector((state: RootState) => state.login)

  useEffect(() => {
    setInitialized(true)
  }, [])

  if (!initialized) {
    return <div>Loading...</div>
  }

  return (
    <Router>
      {renderRoute({ routes, isAuth: isAuth, layout: MainLayout })}
    </Router>
  )
}

export default App
