import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import { withTitle } from './components/withTitle'
import CommonLayout from './common/layout/CommonLayout'
import LoginForm from './domains/login'
import { IRenderRouter } from './interfaces'
import Verification from './domains/verification'
import Main from './domains/main'
import Term from './domains/term'
import NotFound from './domains/notfound'
import Buy from './domains/buy'
import { Talons } from './domains/talons'
import Admins from './domains/info/admins'
import Contacts from './domains/info/contacts'
import Instructions from './domains/info/instructions'

export const routes = [
  {
    path: '/',
    component: withTitle({ component: LoginForm, title: 'Авторизація' }),
    exact: true,
  },
  {
    path: '/verification',
    component: withTitle({ component: Verification, title: 'Авторизація' }),
    exact: true,
  },
  {
    path: '*',
    exact: true,
    // eslint-disable-next-line react/display-name
    render: ({ props, routes, isAuth }: IRenderRouter<any>) => {
      if (isAuth) {
        return renderRoute({ routes, layout: CommonLayout })
      }
      return (
        <Redirect to={{ pathname: '/', state: { from: props.location } }} />
      )
    },
    routes: [
      {
        path: '/main',
        exact: true,
        component: withTitle({ component: Main, title: 'Головна' }),
      },
      {
        path: '/term',
        exact: true,
        component: withTitle({ component: Term, title: 'Термін дії' }),
      },
      {
        path: '/buy',
        exact: true,
        component: withTitle({ component: Buy, title: 'Покупка' }),
      },
      {
        path: '/talons',
        exact: true,
        component: withTitle({ component: Talons, title: 'Одноразові талони' }),
      },
      {
        path: '/admins',
        exact: true,
        component: withTitle({ component: Admins, title: 'Адміністратори' }),
      },
      {
        path: '/contacts',
        exact: true,
        component: withTitle({
          component: Contacts,
          title: 'Контакти менеджера',
        }),
      },
      {
        path: '/instructions',
        exact: true,
        component: withTitle({ component: Instructions, title: 'Інструкції' }),
      },

      {
        path: '*',
        exact: true,
        component: withTitle({ component: NotFound, title: 'Not Found' }),
      },
    ],
  },
]

export const renderRoute = ({
  routes,
  isAuth,
  layout: Layout,
}: IRenderRouter<any>) =>
  routes ? (
    <Layout>
      <Switch>
        {routes.map((route, i) => (
          <Route
            exact={route.exact}
            key={route.key || i}
            path={route.path}
            render={(props) =>
              route.render ? (
                route.render({ props, routes: route.routes, isAuth })
              ) : isAuth ? (
                <Redirect
                  to={{ pathname: '/main', state: { from: props.location } }}
                />
              ) : (
                <route.component {...props} route={route} isAuth={isAuth} />
              )
            }
          />
        ))}
      </Switch>
    </Layout>
  ) : null
