import Cookies from "js-cookie";

import {
    LoginState,
    LoginActionTypes,
    SET_AUTH_STATUS
  } from './types'
  
  const initialState: LoginState = {
    isAuth: !!Cookies.get("token"),
    chosenAccount: !!localStorage.getItem("account")
  }
  
  export function loginReducer(
    state = initialState,
    action: LoginActionTypes
  ): LoginState {
    switch (action.type) {
      case SET_AUTH_STATUS:
        return {
            ...state,
          ...action.payload
        }
      default:
        return state
    }
  }