import { Modal } from 'antd'
import React, { FC, useContext } from 'react'
import { continuationOfValidityPeriod } from '../../../services/apiService'
import { AppContext, AppContextInterface } from '../../../AppProvider'

interface ContinuationModalProps {
  visible: boolean
  document: string
  cancelContiuation: () => void
}

const ContinuationModal: FC<ContinuationModalProps> = (props) => {
  const { getUsersData } = useContext(AppContext) as AppContextInterface

  const confirmContiuation = () => {
    continuationOfValidityPeriod(props.document).then((res) => {
      if (res.Status === 0) {
        Modal.success({
          title: 'Успішно',
          content: res.Result.Message,
          centered: true,
        })
        getUsersData()
      }
    })
    props.cancelContiuation()
  }

  return (
    <Modal
      title={'Продовження терміну дії'}
      visible={props.visible}
      onOk={() => {
        confirmContiuation()
      }}
      onCancel={() => {
        props.cancelContiuation()
      }}
      okText="Продовжити"
      cancelText="Ні"
    >
      <div className="refund-info-container">
        <span>
          {`Термін дії обраної партії буде автоматично продовжено на 1 місяць.`}
        </span>
      </div>
    </Modal>
  )
}

export default ContinuationModal
