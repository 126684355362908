import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Button, Checkbox, Col, Row, Space, Spin, Table } from 'antd'
import { RouteComponentProps } from 'react-router-dom'
import 'moment/locale/uk'
import { useDispatch, useSelector } from 'react-redux'
import { setLoading } from '../../store/loader/actions'
import { RootState } from '../../store'
import { connectAccount, postChangeUser } from '../../services/apiService'
import {
  getAllFuelsColumns,
  getColumns,
  getTransactionsColumns,
  getWalletColumns,
} from './columns'
import Modal from 'antd/lib/modal/Modal'
import { IBalance, IFuels, showMainModal } from '../../interfaces'
import { useWindowSize } from '../../hooks'
import { balancesAdapter } from '../../services/adapters'
import { ReloadBtn } from '../../components/ReloadBtn'
import { EMPTY_GUID, FUELS } from '../../constants'
import { AppContext, AppContextInterface } from '../../AppProvider'
import HistoryModal from './components/HistoryModal'
import AccountSelect from '../../components/selects/AccountSelect'
import WalletSelect from '../../components/selects/WalletSelect'
import FuelSelect from '../../components/selects/FuelSelect'
import openNotification from '../../services/openNotification'
import TransferModal from './components/TransferModal'
import CreationNewUserModal from './components/CreationNewUserModal'

interface IWalletColumn {
  title: string
  dataIndex: string
  key: string
  width: number
  fixed: boolean
  render: Function
}

export const TRANSFER_TYPE = {
  move: 'move',
  refill: 'refill',
}

const EMPTY_COLUMN = {
  title: '',
  key: 'empty_column',
}

const Main: React.FC<RouteComponentProps> = ({ history }) => {
  const { wallet, walletAccounts, getUsersData } = useContext(
    AppContext
  ) as AppContextInterface
  const dispatch = useDispatch()
  const [transferType, setTransferType] = useState<string>()
  const { width } = useWindowSize()
  const { loading } = useSelector((state: RootState) => state.loader)

  const [balances, setBalances] = useState<Array<IBalance | Partial<IBalance>>>(
    []
  )
  const [filteredBalances, setFilteredBalances] = useState<
    Array<IBalance | Partial<IBalance>>
  >([])
  const [showUnblockUsers, setShowUnblockUsers] = useState<boolean>(false)
  const [showRemains, setShowRemains] = useState<boolean>(false)
  const [undistributedBalance, setUndistributedBalance] = useState<IBalance>()
  const [walletBalance, setWalletBalance] = useState<Array<Partial<IBalance>>>(
    []
  )
  const [alias, setAlias] = useState<string | undefined>()
  const [showModal, setShowConfirm] = useState<showMainModal>({
    showConfirm: false,
    showNewNumber: false,
    showNewAlias: false,
    showTransactions: false,
    showTransfer: false,
    showInvite: false,
  })
  const [user, setUser] = useState<IBalance>()

  const [{ columnsU, columnsW, columnsT }, setColumns] = useState<{
    columnsU: any[]
    columnsW: any[]
    columnsT: any[]
  }>({ columnsU: [], columnsW: [], columnsT: [] })

  const allFuelsColumns: Array<any> = getAllFuelsColumns()
  const [selectedColumns, setSelectedColumns] = useState<Array<IWalletColumn>>(
    []
  )

  const columnsLocalStorage = localStorage.getItem('columns')
  const [columnsList, setColumnsList] = useState(
    columnsLocalStorage ? JSON.parse(columnsLocalStorage || '') : []
  )

  const blockUser = () => {
    if (user) {
      postChangeUser(wallet, user, true).then((res) => {
        if (res.Status === 0) {
          user['status'] = !user?.status
          setBalances([...balances])
          setShowConfirm({ ...showModal, showConfirm: false })
        }
      })
    }
    getUsersData()
  }

  const confirmBlockUser = (user: IBalance) => {
    setUser(user)
    setShowConfirm({
      ...showModal,
      showConfirm: true,
      showTransactions: false,
    })
  }

  const transferAccount = (data: IBalance | undefined, type: string) => {
    setUser(data)
    setTransferType(type)
    setShowConfirm({
      ...showModal,
      showTransfer: true,
      showTransactions: false,
      showConfirm: false,
    })
  }

  const showInviteModal = (data: IBalance) => () => {
    setUser(data)
    setShowConfirm({
      ...showModal,
      showInvite: true,
      showConfirm: false,
    })
  }

  const sendInvite = () => {
    if (user) {
      const values = {
        alias: user.alias,
        telephone: user.userPhone,
      }
      dispatch(setLoading(true))
      connectAccount(wallet, values).then((res) => {
        dispatch(setLoading(false))
        if (res.Status === 0) {
          openNotification(
            'success',
            'Вказаному користувачеві відправлено запрошення на приєднання. Після його підтвердження даної процедури в застосунку Вам надійде push-повідомлення'
          )
          setShowConfirm({ ...showModal, showInvite: false })
          getUsersData()
        }
      })
    }
  }

  const openHistory = (data: IBalance) => {
    setUser(data)
    setShowConfirm({ ...showModal, showTransactions: true, showConfirm: false })
  }

  const editAlias = (
    data: IBalance,
    balances: (IBalance | Partial<IBalance>)[]
  ) => {
    if (data?.edit && data.alias !== alias) {
      postChangeUser(wallet, { ...data, alias: alias }).then((res) => {
        balances.map((item) => {
          item['edit'] = false
          return item
        })
        data['alias'] = alias || ''
        setBalances([...balances])
      })
    } else {
      setAlias(data.alias || '')
    }
    balances.map((item) => {
      if (data.account !== item.account) {
        item['edit'] = false
      }
      return item
    })
    data['edit'] = !data?.edit
    setBalances([...balances])
  }

  useMemo(() => {
    const fcTWidth = width && width < 575 ? width * 0.4 : 150
    setColumns({
      columnsT: getTransactionsColumns(fcTWidth),
      columnsU: [
        ...getWalletColumns({
          width,
          showTransactions: openHistory,
          transferAccount,
        }),
        ...selectedColumns,
        EMPTY_COLUMN,
      ],
      columnsW: [
        ...getColumns({
          blockUser: confirmBlockUser,
          width,
          showTransactions: openHistory,
          transferAccount,
          editAlias,
          balances,
          onEdit: setAlias,
          alias,
          showInviteModal,
        }),
        ...selectedColumns,
        EMPTY_COLUMN,
      ],
    })
  }, [wallet, width, balances, alias, selectedColumns]) // eslint-disable-line react-hooks/exhaustive-deps

  const onRemainsClickHandler = () => {
    setShowRemains(!showRemains)
  }

  const onShowUnblockUsersClickHandler = () => {
    setShowUnblockUsers(!showUnblockUsers)
  }

  useEffect(() => {
    if (walletAccounts) {
      let myAccount: IBalance | {} = {}
      let undistributedAccount: IBalance | {} = {}
      const accounts: Array<Partial<IBalance>> = balancesAdapter(
        walletAccounts['Аккаунты'],
        true
      )
      const walletBalance = balancesAdapter(walletAccounts['Счет'], false)
      const data = accounts.filter((item: any) => {
        if (item.userPhone === localStorage.getItem('ik:phone')) {
          item['block'] = false
          myAccount = item
        }
        if (item.account === EMPTY_GUID) {
          item['block'] = false
          undistributedAccount = item
        }
        return (
          item.userPhone !== localStorage.getItem('ik:phone') &&
          item.account !== EMPTY_GUID
        )
      })
      data.unshift(myAccount as IBalance)
      setUndistributedBalance(undistributedAccount as IBalance)
      setWalletBalance([...walletBalance, undistributedAccount as IBalance])
      setBalances(data)
      setFilteredBalances(data.filter((balance) => balance.status))
      if (!showRemains) {
        setBalances(data)
      }
    }
  }, [walletAccounts, showRemains])

  useEffect(() => {
    if (showUnblockUsers) {
      setFilteredBalances(balances.filter((balance) => balance.status))
    }

    if (showRemains) {
      setFilteredBalances(filteredBalances.filter((balance) => balance.status))
    }
  }, [showUnblockUsers]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    localStorage.setItem('columns', JSON.stringify(columnsList))
    columnsList.length
      ? setSelectedColumns(
          allFuelsColumns.filter(
            (column) => columnsList.indexOf(column.key) > -1
          )
        )
      : setSelectedColumns(allFuelsColumns)
  }, [columnsList]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (showRemains) {
      const remains = [
        walletBalance[0].a100_Mustang?.count ? 'a100_Mustang' : null,
        walletBalance[0].a95_Mustang?.count ? 'a95_Mustang' : null,
        walletBalance[0].a95?.count ? 'a95' : null,
        walletBalance[0].dp_Mustang_Plus?.count ? 'dp_Mustang_Plus' : null,
        walletBalance[0].dp?.count ? 'dp' : null,
        walletBalance[0].gas?.count ? 'gas' : null,
        walletBalance[0].a92?.count ? 'a92' : null,
      ]
      const remainsColumns = remains.filter((remain) => remain != null)
      setColumnsList(remainsColumns)
      setBalances(
        balances.filter(
          (balance) =>
            balance.a100_Mustang?.count ||
            balance.a95_Mustang?.count ||
            balance.a92?.count ||
            balance.a95?.count ||
            balance.dp?.count ||
            balance.dp_Mustang_Plus?.count ||
            balance.gas?.count
        )
      )
      if (showUnblockUsers) {
        setFilteredBalances(
          filteredBalances.filter(
            (balance) =>
              balance.a100_Mustang?.count ||
              balance.a95_Mustang?.count ||
              balance.a92?.count ||
              balance.a95?.count ||
              balance.dp?.count ||
              balance.dp_Mustang_Plus?.count ||
              balance.gas?.count
          )
        )
      } else {
        setFilteredBalances(
          balances.filter(
            (balance) =>
              balance.a100_Mustang?.count ||
              balance.a95_Mustang?.count ||
              balance.a92?.count ||
              balance.a95?.count ||
              balance.dp?.count ||
              balance.dp_Mustang_Plus?.count ||
              balance.gas?.count
          )
        )
      }
    } else {
      setColumnsList(FUELS.map((fuel: IFuels) => fuel.key))
    }
  }, [showRemains, walletAccounts]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Spin spinning={loading}>
      <Row
        gutter={[16, 16]}
        align="top"
        justify="space-between"
        className="height-auto overflow-auto"
      >
        <Col>
          <AccountSelect />
        </Col>

        <Col>
          <WalletSelect />
        </Col>

        <Col>
          <FuelSelect onChange={setColumnsList} useFuelKey={true} />
        </Col>

        <Col>
          <Space size={10}>
            <ReloadBtn btnText="Оновити" />

            <Button
              className="add-user-btn"
              onClick={() =>
                setShowConfirm({ ...showModal, showNewNumber: true })
              }
            >
              + Новий користувач
            </Button>
          </Space>
        </Col>
        <div style={{ width: '100%' }}>
          <Checkbox onChange={onRemainsClickHandler} className="first-checkbox">
            відображати лише із залишками
          </Checkbox>
          <Checkbox onChange={onShowUnblockUsersClickHandler}>
            відображати лише не заблокованих користувачів
          </Checkbox>
        </div>
        <Table
          columns={width && width < 480 ? [columnsU[0], columnsU[1]] : columnsU}
          dataSource={walletBalance}
          size="small"
          pagination={false}
          rowKey={(record) => `${record.key}Wallet`}
          className="table-full-width table-account"
          scroll={{ x: 'max-content' }}
          expandable={
            width && width < 480
              ? {
                  expandedRowRender: (v) =>
                    selectedColumns.map((item, index) => (
                      <div className="expandable-wrap" key={index}>
                        <div>{item.title}</div>
                        <div className="green">
                          {item.render(v[item.key as keyof IBalance])}
                        </div>
                      </div>
                    )),
                  columnWidth: 33,
                }
              : undefined
          }
        />
        <Table
          columns={width && width < 480 ? [columnsW[0], columnsW[1]] : columnsW}
          locale={{
            triggerDesc: 'Сортувати за спаданням',
            triggerAsc: 'Сортувати за зростанням',
            cancelSort: 'Скасувати сортування',
          }}
          dataSource={showUnblockUsers ? filteredBalances : balances}
          size="small"
          pagination={{
            showSizeChanger: true,
            pageSizeOptions: ['10', '25', '50', '100'],
            defaultPageSize: 10,
          }}
          className="table-full-width"
          scroll={{ x: 'max-content' }}
          expandable={
            width && width < 480
              ? {
                  expandedRowRender: (v) =>
                    selectedColumns.map((item, index) => (
                      <div className="expandable-wrap" key={index}>
                        <div>{item.title}</div>
                        <div className="green">
                          {item.render(v[item.key as keyof IBalance])}
                        </div>
                      </div>
                    )),
                  columnWidth: 33,
                }
              : undefined
          }
        />
      </Row>

      {/********************* BLOKING USER ***********************/}
      <Modal
        title={
          user?.status && user?.block
            ? 'Блокування користувача'
            : !user?.status && user?.block
            ? 'Розблокування користувача'
            : ''
        }
        visible={showModal.showConfirm}
        onOk={blockUser}
        onCancel={() => setShowConfirm({ ...showModal, showConfirm: false })}
        okText="Так"
        cancelText="Ні"
      >
        <p>
          Ви дійсно хочете{' '}
          {user?.status && user?.block
            ? 'заблокувати'
            : !user?.status && user?.block
            ? 'розблокувати'
            : ''}{' '}
          користувача {user?.alias} з номером {user?.accountName}
        </p>
      </Modal>

      {/********************* CREATION NEW USER MODAL ***********************/}
      <CreationNewUserModal
        showModal={showModal}
        setShowConfirm={setShowConfirm}
      />

      {/********************* HISTORY MODAL ***********************/}
      <HistoryModal
        user={user}
        showModal={showModal}
        transferAccount={transferAccount}
        confirmBlockUser={confirmBlockUser}
        setShowConfirm={setShowConfirm}
        columnsT={columnsT}
      />

      {/******************** TRANSFER MODAL ********************/}
      <TransferModal
        user={user}
        showModal={showModal}
        transferType={transferType}
        walletBalance={walletBalance}
        balances={balances}
        undistributedBalance={undistributedBalance}
        setShowConfirm={setShowConfirm}
      />

      {/******************** INVITE USER ********************/}
      <Modal
        title={'Прив’язка користувача'}
        visible={showModal.showInvite}
        onOk={sendInvite}
        onCancel={() => setShowConfirm({ ...showModal, showInvite: false })}
        okText="Так"
        cancelText="Ні"
      >
        <span>
          {`Ви дійсно хочете надіслати повторне запрошення на прив’язку користувача 
          ${user?.alias} з номером ${user?.accountName}?`}
        </span>
      </Modal>
    </Spin>
  )
}

export default Main
