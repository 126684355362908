import {
  LoaderState,
  LoaderActionTypes,
  SET_LOADING
} from './types'

const initialState: LoaderState = {
  loading: false
}

export function loaderReducer(
  state = initialState,
  action: LoaderActionTypes
): LoaderState {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload
      }
    default:
      return state
  }
}