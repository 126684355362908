import { DatePicker, Modal, Select, Table } from 'antd'
import React, { FC, useContext, useEffect, useState } from 'react'
import { ReactComponent as Refill } from '../../../assets/add.svg'
import { ReactComponent as Transfer } from '../../../assets/pass.svg'
import { ReactComponent as Download } from '../../../assets/download.svg'
import { TRANSFER_TYPE } from '..'
import { IBalance, ITransactions, showMainModal } from '../../../interfaces'
import locale from '../../../../node_modules/antd/lib/calendar/locale/uk_UA.js'
import { TOTAL_ACCOUNT, TRANSACTION_TYPES } from '../../../constants'
import {
  getTransactions,
  transactionsReport,
} from '../../../services/apiService'
import { AppContext, AppContextInterface } from '../../../AppProvider'
import { downloadXLSX } from '../../../services/fileDownload'
import { transactionsAdapter } from '../../../services/adapters'

enum TransactionTypeEnum {
  All = 'Всі транзакції',
  Service = 'Службові',
  Sale = 'Заправка',
  Replenishment = 'Поповнення',
}

interface IFHistorymodal {
  user: IBalance | undefined
  showModal: showMainModal
  transferAccount: Function
  confirmBlockUser: Function
  setShowConfirm: Function
  columnsT: Array<any>
}

const HistoryModal: FC<IFHistorymodal> = (props) => {
  const { wallet } = useContext(AppContext) as AppContextInterface
  const [loadingTransactions, setLoadingTransactions] = useState<boolean>(false)
  const [dateTransactions, setDateTransactions] = useState<
    [moment.Moment, moment.Moment] | undefined
  >()
  const [transactions, setTransactions] = useState<Array<ITransactions>>([])
  const [filteredTransactions, setFilteredTransactions] = useState<
    Array<ITransactions>
  >([])
  const { RangePicker }: any = DatePicker
  const [transTypeFilter, setTransTypeFilter] = useState<string>('')

  const user = props.user
  const showModal = props.showModal
  const transferAccount = props.transferAccount
  const confirmBlockUser = props.confirmBlockUser
  const setShowConfirm = props.setShowConfirm

  useEffect(() => {
    if (showModal.showTransactions) {
      getHistoryData()
      setTransTypeFilter(TransactionTypeEnum.All)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal.showTransactions, dateTransactions])

  const getHistoryData = () => {
    const date1 = dateTransactions
      ? dateTransactions[0].format('YYYY-MM-DDTHH:mm:ss') + 'Z'
      : undefined
    const date2 = dateTransactions
      ? dateTransactions[1].format('YYYY-MM-DDTHH:mm:ss') + 'Z'
      : undefined

    if (user) {
      setLoadingTransactions(true)
      getTransactions(user.userPhone, wallet, date1, date2)
        .then((res) => {
          if (res.Status === 0) {
            const trans = transactionsAdapter(res.Result)
            setTransactions(trans)
            setFilteredTransactions(trans)
          }
        })
        .finally(() => setLoadingTransactions(false))
    }
  }

  const onChangeDate = (value: any, dateString: string[]) => {
    setDateTransactions(value)
  }

  const downloadReport = (byGroup: boolean = false) => {
    const date1 = dateTransactions
      ? dateTransactions[0].format('YYYY-MM-DDTHH:mm:ss') + 'Z'
      : undefined
    const date2 = dateTransactions
      ? dateTransactions[1].format('YYYY-MM-DDTHH:mm:ss') + 'Z'
      : undefined

    if (user) {
      transactionsReport(user?.userPhone, wallet, date1, date2, byGroup)
        .then((res) => {
          downloadXLSX(res.Result.DOCUMENT, res.Result.FileName)
        })
        .catch((err) => console.log(err))
    }
  }

  const filterTypeTransaction = (value: string) => {
    setTransTypeFilter(value)

    let transType: string[] = []

    switch (value) {
      case TransactionTypeEnum.Sale:
        transType.push(TRANSACTION_TYPES[0])
        break
      case TransactionTypeEnum.Service:
        transType.push(TRANSACTION_TYPES[3])
        transType.push(TRANSACTION_TYPES[5])
        transType.push(TRANSACTION_TYPES[6])
        transType.push(TRANSACTION_TYPES[7])
        transType.push(TRANSACTION_TYPES[8])
        break
      case TransactionTypeEnum.Replenishment:
        transType.push(TRANSACTION_TYPES[1])
        transType.push(TRANSACTION_TYPES[2])
        transType.push(TRANSACTION_TYPES[9])
        break
    }

    if (transType.length) {
      setFilteredTransactions(
        transactions.filter((transaction) =>
          transType.includes(transaction.type)
        )
      )
    } else {
      setFilteredTransactions(transactions)
    }
  }

  return (
    <Modal
      className="transactions"
      width="800"
      visible={showModal.showTransactions}
      footer={null}
      onCancel={() => {
        setShowConfirm({ ...showModal, showTransactions: false })
        setDateTransactions(undefined)
      }}
      title={
        <h1>
          {user?.alias || user?.accountName}{' '}
          {user?.status && user?.block ? (
            <span className="red" onClick={() => confirmBlockUser(user)}>
              Заблокувати
            </span>
          ) : !user?.status && user?.block ? (
            <span className="green" onClick={() => confirmBlockUser(user)}>
              Розблокувати
            </span>
          ) : null}
        </h1>
      }
    >
      <div className="content">
        <div>
          <div className="transaction-actions">
            <div className="refill-actions">
              {user?.key !== TOTAL_ACCOUNT && (
                <>
                  <span
                    className="refill cursor"
                    onClick={() => {
                      transferAccount(user, TRANSFER_TYPE.refill)
                    }}
                  >
                    <Refill /> Поповнити
                  </span>
                  <span
                    className="refill cursor"
                    onClick={() => {
                      transferAccount(user, TRANSFER_TYPE.move)
                    }}
                  >
                    <Transfer /> Переказати
                  </span>
                </>
              )}

              <span
                className="refill cursor"
                onClick={() => {
                  downloadReport(false)
                }}
              >
                <Download /> Завантажити звіт
              </span>

              {user?.key === TOTAL_ACCOUNT && (
                <span
                  className="refill cursor"
                  onClick={() => {
                    downloadReport(true)
                  }}
                >
                  <Download /> Звіт "Конвертер"
                </span>
              )}
            </div>
          </div>

          <div className="transaction-period">
            <span>Транзакції по рахунку за </span>
            <Select
              placeholder="Обрати тип транзакції"
              className="select-width"
              getPopupContainer={(node) => node.parentNode}
              onChange={filterTypeTransaction}
              value={transTypeFilter}
              options={[
                {
                  value: TransactionTypeEnum.All,
                },
                {
                  value: TransactionTypeEnum.Sale,
                },
                {
                  value: TransactionTypeEnum.Replenishment,
                },
                {
                  value: TransactionTypeEnum.Service,
                },
              ]}
            ></Select>

            {showModal.showTransactions && (
              <RangePicker
                locale={locale}
                onChange={onChangeDate}
                inputReadOnly={true}
                value={dateTransactions}
                getPopupContainer={(node: any) =>
                  node.parentNode as HTMLElement
                }
              />
            )}
          </div>
        </div>
        <Table
          columns={props.columnsT}
          locale={{
            triggerDesc: 'Сортувати за спаданням',
            triggerAsc: 'Сортувати за зростанням',
            cancelSort: 'Скасувати сортування',
          }}
          loading={loadingTransactions}
          dataSource={filteredTransactions}
          size="small"
          pagination={{
            position: ['bottomRight'],
            showSizeChanger: false,
          }}
          rowKey={(record) => record.key}
          scroll={{ x: 650 }}
        />
      </div>
    </Modal>
  )
}

export default HistoryModal
