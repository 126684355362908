import React from 'react';
import { Helmet } from 'react-helmet';

interface ITitle {
    name: string
}

const Title = ({ name }: ITitle) => {
    var defaultTitle = 'eTalon';
    return (
        <Helmet>
            <title>{name ? name : defaultTitle}</title>
        </Helmet>
    );
};

export default Title