export const INDEX_PAGE_SIZE_DEFAULT = 10;

export const DEFAULT_PAGE_SIZE = ['10', '25', '50', '100'];

export const FORMAT_DATE = "DD.MM.YYYY";

export const UNDISTRIBUTED_TITLE = "Нерозподілений баланс";

export const UNDISTRIBUTED_PHONE = "";

export const TOTAL_ACCOUNT = "total";

export const EMPTY_GUID = "00000000-0000-0000-0000-000000000000";

export const FUELS_ENUM = {
  a100_Mustang: "1644b5d5-8d4c-11de-849b-00e081b1bf9b",
  a95_Mustang: "c784499e-8e15-11de-849b-00e081b1bf9b",
  a95: "1f57ca06-dc2a-11da-8dd9-00e0812c63f2",
  dp_Mustang_Plus: "a774566d-08ab-11e3-995c-00e081b1bf9b",
  dp: "9da05458-f626-11da-98ba-00e0812c63f2",
  gas: "6bab0185-05e3-11db-acaa-00e0812c63f2",
  a92: "8b7b69ca-f45c-11da-98ba-00e0812c63f2"
};

export const FUELS = [
  {
    name: 'A-100 Mustang',
    key: 'a100_Mustang',
    value: FUELS_ENUM.a100_Mustang
  },
  {
    name: 'A-95 Mustang',
    key: 'a95_Mustang',
    value: FUELS_ENUM.a95_Mustang
  },
  {
    name: 'A-95',
    key: 'a95',
    value: FUELS_ENUM.a95
  },
  {
    name: 'A-92',
    key: 'a92',
    value: FUELS_ENUM.a92
  },
  {
    name: 'ДП Mustang+',
    key: 'dp_Mustang_Plus',
    value: FUELS_ENUM.dp_Mustang_Plus
  },
  {
    name: 'ДП',
    key: 'dp',
    value: FUELS_ENUM.dp
  },
  {
    name: 'Газ нафтовий',
    key: 'gas',
    value: FUELS_ENUM.gas
  },
];

export const STATUS_CONNECTION = {
  connected: 1,
  waiting: 2,
  rejected: 3,
}

export const TRANSACTION_TYPES = {
  0: "Заправка",
  1: "Поповнення",
  2: "Отримання",
  3: "Передача",
  4: "Нарахування реферальних бонусів",
  5: "Заблокований баланс",
  6: "Розблокування балансу",
  7: "Генерація одноразових талонів",
  8: "Повернення одноразових талонів",
  9: "Повернення",
  10: "Інше"
}