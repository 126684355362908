import React from 'react'
import {
  CheckOutlined,
  EditOutlined,
  EyeOutlined,
  LockOutlined,
  UnlockOutlined,
  InfoCircleFilled,
  ExclamationOutlined,
  StopOutlined,
  ClockCircleOutlined,
} from '@ant-design/icons'
import { ReactComponent as Refill } from '../../assets/add.svg'
import { ReactComponent as Transfer } from '../../assets/pass.svg'
import { ReactComponent as Download } from '../../assets/download.svg'
import moment from 'moment'
import { TRANSFER_TYPE } from '.'
import { Input, Popover, Modal } from 'antd'
import {
  FuelCards,
  IBalance,
  Invoice,
  ITermPart,
  ITFuelCardsTransactions,
  ITransactions,
  IWalletAccounts,
} from '../../interfaces'
import { STATUS_CONNECTION, FORMAT_DATE, FUELS } from '../../constants'
import { downloadInvoice } from '../../services/apiService'
import { downloadPDF } from '../../services/fileDownload'

const totalAccount = {
  title: 'Всього по рахунку',
  content:
    'Всього по рахунку - це сума нерозподіленого балансу і балансу усіх користувачів рахунку.',
}

const contentBlockPart = (
  <>
    <div>Термін дії партії закінчився.</div>
    <div>Вона заблокована.</div>
  </>
)

const contentTermExpires = <div>Термін дії партії добігає кінця.</div>

const RefillIcon = ({ visible }: { visible?: boolean }) => (
  <Popover content="Поповнення" trigger="hover">
    <Refill />
  </Popover>
)

const TransferIcon = ({ visible }: { visible?: boolean }) => (
  <Popover content="Переказ" trigger="hover">
    <Transfer />
  </Popover>
)

const EyeIcon = ({ visible }: { visible?: boolean }) => (
  <Popover content="Транзакції" visible={visible}>
    <EyeOutlined />
  </Popover>
)

const DownloadIcon = ({ visible }: { visible?: boolean }) => (
  <Popover content="Завантажити" trigger="hover">
    <Download />
  </Popover>
)

const ClockCircleOutlinedIcon = () => (
  <Popover content="Продовження терміну дії" trigger="hover">
    Продовжити <ClockCircleOutlined />
  </Popover>
)

type Column = {
  blockUser: Function
  width?: number
  showTransactions: Function
  transferAccount: Function
  wallet?: string
  startTransactoins?: string
  endTransactions?: string
  editAlias: (
    data: IBalance,
    balances: (IBalance | Partial<IBalance>)[],
    v?: string
  ) => void
  balances: (IBalance | Partial<IBalance>)[]
  onEdit: (v: string) => void
  alias?: string
  showInviteModal: (data: IBalance) => () => void
}

type WalletColumn = {
  width?: number
  showTransactions: Function
  transferAccount: Function
  wallet?: string
  startTransactoins?: string
  endTransactions?: string
}

export const getAllFuelsColumns = () => {
  return [
    {
      title: 'A-100 Mustang',
      dataIndex: 'a100_Mustang',
      key: 'a100_Mustang',
      width: 110,
      sorter: {
        compare: (a: any, b: any, dir: string) => {
          const first = a.a100_Mustang.count || 0
          const second = b.a100_Mustang.count || 0
          return a.block && b.block ? first - second : dir === 'ascend'
        },
      },
      render: (v: { count: string }) => {
        return (
          <span>
            {v.count || 0}
            <span className="dem">л.</span>
          </span>
        )
      },
    },
    {
      title: 'A-95 Mustang',
      dataIndex: 'a95_Mustang',
      key: 'a95_Mustang',
      width: 110,
      sorter: {
        compare: (a: any, b: any, dir: string) => {
          const first = a.a95_Mustang.count || 0
          const second = b.a95_Mustang.count || 0
          return a.block && b.block ? first - second : dir === 'ascend'
        },
      },
      render: (v: { count: string }) => {
        return (
          <span>
            {v.count || 0}
            <span className="dem">л.</span>
          </span>
        )
      },
    },
    {
      title: 'A-95',
      dataIndex: 'a95',
      key: 'a95',
      width: 110,
      sorter: {
        compare: (a: any, b: any, dir: string) => {
          const first = a.a95.count || 0
          const second = b.a95.count || 0
          return a.block && b.block ? first - second : dir === 'ascend'
        },
      },
      render: (v: { count: string }) => {
        return (
          <span>
            {v.count || 0}
            <span className="dem">л.</span>
          </span>
        )
      },
    },
    {
      title: 'A-92',
      dataIndex: 'a92',
      key: 'a92',
      width: 110,
      sorter: {
        compare: (a: any, b: any, dir: string) => {
          const first = a.a92.count || 0
          const second = b.a92.count || 0
          return a.block && b.block ? first - second : dir === 'ascend'
        },
      },
      render: (v: { count: string }) => {
        return (
          <span>
            {v.count || 0}
            <span className="dem">л.</span>
          </span>
        )
      },
    },
    {
      title: 'ДП Mustang+',
      dataIndex: 'dp_Mustang_Plus',
      key: 'dp_Mustang_Plus',
      width: 110,
      sorter: {
        compare: (a: any, b: any, dir: string) => {
          const first = a.dp_Mustang_Plus.count || 0
          const second = b.dp_Mustang_Plus.count || 0
          return a.block && b.block ? first - second : dir === 'ascend'
        },
      },
      render: (v: { count: string }) => {
        return (
          <span>
            {v.count || 0}
            <span className="dem">л.</span>
          </span>
        )
      },
    },
    {
      title: 'ДП',
      dataIndex: 'dp',
      key: 'dp',
      width: 110,
      sorter: {
        compare: (a: any, b: any, dir: string) => {
          const first = a.dp.count || 0
          const second = b.dp.count || 0
          return a.block && b.block ? first - second : dir === 'ascend'
        },
      },
      render: (v: { count: string }) => {
        return (
          <span>
            {v.count || 0}
            <span className="dem">л.</span>
          </span>
        )
      },
    },
    {
      title: 'Газ нафтовий',
      dataIndex: 'gas',
      key: 'gas',
      width: 110,
      sorter: {
        compare: (a: any, b: any, dir: string) => {
          const first = a.gas.count || 0
          const second = b.gas.count || 0
          return a.block && b.block ? first - second : dir === 'ascend'
        },
      },
      render: (v: { count: string }) => {
        return (
          <span>
            {v.count || 0}
            <span className="dem">л.</span>
          </span>
        )
      },
    },
  ]
}

export const getWalletColumns = ({
  width,
  showTransactions,
  transferAccount,
}: WalletColumn) => {
  const visiblePopover = !!width && width < 480 ? false : undefined
  return [
    {
      title: 'Баланс рахунку',
      dataIndex: 'alias',
      key: 'alias',
      width: width && width < 575 ? width * 0.6 : 240,
      fixed: true,
      render: (v: boolean, data: any) => {
        return (
          <span>
            {data.alias}{' '}
            {!data.account && (
              <InfoCircleFilled onClick={() => Modal.info(totalAccount)} />
            )}
          </span>
        )
      },
    },
    {
      title: 'Дії',
      dataIndex: 'status',
      key: 'status',
      width: width && width > 480 && 130,
      fixed: width && width > 480,
      render: (v: boolean, data: any) => {
        return (
          <span>
            {data.account ? (
              <span className="actions">
                <span
                  className="refill cursor"
                  onClick={() => {
                    transferAccount(data, TRANSFER_TYPE.refill)
                  }}
                >
                  <RefillIcon visible={visiblePopover} />
                </span>
                <span
                  className="refill cursor"
                  onClick={() => {
                    transferAccount(data, TRANSFER_TYPE.move)
                  }}
                >
                  <TransferIcon visible={visiblePopover} />
                </span>
                <span
                  className="eye cursor"
                  onClick={() => {
                    showTransactions(data)
                  }}
                >
                  <EyeIcon visible={visiblePopover} />
                </span>
              </span>
            ) : (
              <span className="actions">
                <span
                  className="eye cursor"
                  onClick={() => {
                    showTransactions(data)
                  }}
                >
                  <EyeIcon visible={visiblePopover} />
                </span>
              </span>
            )}
          </span>
        )
      },
    },
  ]
}

export const getInvoiceColumn = (fcWidth: number | undefined) => {
  const download = (uuid: string) => {
    downloadInvoice(uuid)
      .then((res) => {
        downloadPDF(res.Result.DOCUMENT, res.Result.FileName)
      })
      .catch((err) => console.log(err))
  }

  return [
    {
      title: 'Дата',
      dataIndex: 'Дата',
      key: 'Дата',
      width: fcWidth,
      sorter: {
        compare: (a: Invoice, b: Invoice) => {
          const first = new Date(a['Дата']).getTime()
          const second = new Date(b['Дата']).getTime()
          return first - second
        },
      },
      render: (v: string) => {
        return moment(v).utc().format('DD.MM.YYYY HH:mm:ss')
      },
    },
    {
      title: 'Номенклатура',
      dataIndex: 'НоменклатураНаименование',
      key: 'НоменклатураНаименование',
      width: 150,
      sorter: {
        compare: (a: Invoice, b: Invoice) =>
          b['НоменклатураНаименование']?.localeCompare(
            a['НоменклатураНаименование'] ? a['НоменклатураНаименование'] : ''
          ),
      },
      render: (v: string) => {
        return <span>{v}</span>
      },
    },
    {
      title: 'Всього літрів',
      dataIndex: 'Количество',
      key: 'Количество',
      width: 150,
      sorter: {
        compare: (a: Invoice, b: Invoice) => a['Количество'] - b['Количество'],
      },
      render: (v: number) => {
        return <span>{v}</span>
      },
    },
    {
      title: 'Сума',
      dataIndex: 'Сумма',
      key: 'Сумма',
      width: 150,
      sorter: {
        compare: (a: Invoice, b: Invoice) => a['Сумма'] - b['Сумма'],
      },
      render: (v: number) => {
        return <span>{v}</span>
      },
    },
    {
      title: 'Вхідний номер',
      dataIndex: 'ВходящийНомер',
      key: 'ВходящийНомер',
      width: 150,
      sorter: {
        compare: (a: Invoice, b: Invoice) =>
          b['ВходящийНомер']?.localeCompare(
            a['ВходящийНомер'] ? a['ВходящийНомер'] : ''
          ),
      },
      render: (v: string) => {
        return <span>{v}</span>
      },
    },
    {
      title: 'Рахунок-фактура',
      dataIndex: 'НомерСчета',
      key: 'НомерСчета',
      width: 150,
      sorter: {
        compare: (a: Invoice, b: Invoice) =>
          b['НомерСчета']?.localeCompare(
            a['НомерСчета'] ? a['НомерСчета'] : ''
          ),
      },
      render: (v: string) => {
        return `№${v}`
      },
    },
    {
      title: 'Статус',
      dataIndex: 'Статус',
      key: 'Статус',
      width: 150,
      render: (v: string) => {
        if (v === 'Оплаченный') {
          return <span className="usedFuelCard">Оплачений</span>
        }
        if (v === 'ОжидаетсяОплата') {
          return <span className="usedFuelCard">Очікується оплата</span>
        }
        if (v === 'Просроченный') {
          return <span className="blockedFuelCard">Прострочений</span>
        }
      },
    },
    {
      title: '',
      dataIndex: 'Статус',
      key: 'Статус',
      width: 75,
      render: (v: string, data: Invoice) => {
        if (v === 'ОжидаетсяОплата') {
          return (
            <span
              className="refill cursor"
              onClick={(event) => {
                event.stopPropagation()
                download(data.УИД)
              }}
            >
              <DownloadIcon />
            </span>
          )
        }
      },
    },
  ]
}

export const getTransactionsColumns = (fcWidth: number | undefined) => {
  return [
    {
      title: 'Дата',
      dataIndex: 'dateT',
      key: 'dateT6',
      width: fcWidth,
      sorter: {
        compare: (a: ITransactions, b: ITransactions) => {
          const first = new Date(a['dateT']).getTime()
          const second = new Date(b['dateT']).getTime()
          return first - second
        },
      },
      render: (v: string) => {
        return moment(v).utc().format('DD.MM.YYYY HH:mm:ss')
      },
    },
    {
      title: 'Номенклатура',
      dataIndex: 'fuelType',
      key: 'fuelType5',
      width: 150,
      sorter: {
        compare: (a: ITransactions, b: ITransactions) =>
          b['fuelType']?.localeCompare(a['fuelType'] ? a['fuelType'] : ''),
      },
    },
    {
      title: 'Партія',
      dataIndex: 'part',
      key: 'part',
      width: 150,
      sorter: {
        compare: (a: ITransactions, b: ITransactions) =>
          b['part']?.localeCompare(a['part'] ? a['part'] : ''),
      },
    },
    {
      title: 'Кількість',
      dataIndex: 'amount',
      key: 'amount4',
      width: 80,
      sorter: {
        compare: (a: ITransactions, b: ITransactions) =>
          a['amount'] - b['amount'],
      },
      render: (v: number) => {
        return (
          <span>
            {v}
            <span className="dem">л.</span>
          </span>
        )
      },
    },
    {
      title: 'Тип транзакції',
      dataIndex: 'type',
      key: 'type',
      width: 150,
      sorter: {
        compare: (a: ITransactions, b: ITransactions) =>
          b['type']?.localeCompare(a['type'] ? a['type'] : ''),
      },
    },
    {
      title: 'Користувач',
      dataIndex: 'user',
      key: 'user',
      width: 150,
      sorter: {
        compare: (a: ITransactions, b: ITransactions) =>
          b['user']?.localeCompare(a['user'] ? a['user'] : ''),
      },
    },
    {
      title: 'АЗК',
      dataIndex: 'gasStation',
      key: 'gasStation',
      width: 150,
    },
  ]
}

export const getColumns = ({
  blockUser,
  width,
  showTransactions,
  transferAccount,
  editAlias,
  balances,
  onEdit,
  alias,
  showInviteModal,
}: Column) => {
  const visiblePopover = !!width && width < 480 ? false : undefined
  return [
    {
      title: 'Баланс користувачів',
      dataIndex: 'alias',
      align: 'left',
      key: 'alias',
      width: width && width < 575 ? width * 0.6 : 240,
      fixed: true,
      sorter: {
        compare: (a: any, b: any, dir: string) => {
          return a.block && b.block
            ? a.alias.localeCompare(b.alias)
            : dir === 'ascend'
        },
      },
      render: (v: boolean, data: any) => {
        const status =
          data.status_connection === STATUS_CONNECTION.waiting ? (
            <span className="yellow">Очікує підтвердження</span>
          ) : data.status_connection === STATUS_CONNECTION.rejected ? (
            <span className="red cursor" onClick={showInviteModal(data)}>
              Запрошення відхилено
            </span>
          ) : null
        return (
          <span>
            <span className="editable">
              {!data.edit ? (
                <span>
                  {data.block ? data.alias || data.accountName : 'Мій баланс'}
                </span>
              ) : (
                <Input
                  autoFocus
                  defaultValue={data.alias}
                  onChange={(e) => {
                    e.target.focus()
                    onEdit(e.target.value)
                  }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      editAlias(data, balances, alias)
                    }
                  }}
                  value={alias}
                />
              )}{' '}
              <span
                className="icon cursor"
                onClick={() => editAlias(data, balances, alias)}
              >
                {!data.edit ? (
                  <EditOutlined />
                ) : (
                  <CheckOutlined className="save" />
                )}
              </span>
              <span>
                {data.status && data.block ? (
                  <span
                    className="green cursor"
                    onClick={() => {
                      blockUser(data)
                    }}
                  >
                    <UnlockOutlined />
                  </span>
                ) : !data.status && data.block ? (
                  <span
                    className="red cursor"
                    onClick={() => {
                      blockUser(data)
                    }}
                  >
                    <LockOutlined />
                  </span>
                ) : null}
              </span>
            </span>
            <span className="alias">{data.accountName}</span>
            <div className="status">{status}</div>
          </span>
        )
      },
    },
    {
      title: 'Дії',
      dataIndex: 'status',
      key: 'status',
      width: width && width > 480 && 130,
      fixed: width && width > 480,
      render: (v: boolean, data: any) => {
        return (
          <span className="actions">
            <span
              className="refill cursor"
              onClick={() => {
                transferAccount(data, TRANSFER_TYPE.refill)
              }}
            >
              <RefillIcon visible={visiblePopover} />
            </span>
            <span
              className="refill cursor"
              onClick={() => {
                transferAccount(data, TRANSFER_TYPE.move)
              }}
            >
              <TransferIcon visible={visiblePopover} />
            </span>
            <span
              className="eye cursor"
              onClick={() => {
                showTransactions(data)
              }}
            >
              <EyeIcon visible={visiblePopover} />
            </span>
          </span>
        )
      },
    },
  ]
}

export const getTermsColumns = (isMobile: boolean, continuation: Function) => {
  const continuationPart = (
    event: React.MouseEvent<HTMLElement>,
    document: string
  ) => {
    event.stopPropagation()
    continuation(document)
  }

  return [
    {
      title: 'Дата поповнення',
      dataIndex: 'date',
      key: 'date',
      width: isMobile ? 100 : 150,
      fixed: !isMobile,
      sorter: {
        compare: (a: ITermPart, b: ITermPart) => {
          const first = new Date(a.date).getTime()
          const second = new Date(b.date).getTime()
          return first - second
        },
      },
      render: (v: string) => moment(v).utc().format(FORMAT_DATE),
    },
    {
      title: 'Партія',
      dataIndex: 'part',
      key: 'part',
      width: isMobile ? 100 : 150,
      sorter: {
        compare: (a: ITermPart, b: ITermPart) =>
          b.fuel_title?.localeCompare(a.fuel_title ? a.fuel_title : ''),
      },
      render: (v: string) => v,
    },
    {
      title: 'Номенклатура',
      dataIndex: 'fuel_title',
      key: 'fuel_title',
      width: isMobile ? 100 : 100,
      sorter: {
        compare: (a: ITermPart, b: ITermPart) =>
          b.fuel_title?.localeCompare(a.fuel_title ? a.fuel_title : ''),
      },
      render: (v: string) => v,
    },
    {
      title: "Об'єм",
      dataIndex: 'count',
      key: 'count',
      width: 100,
      sorter: {
        compare: (a: ITermPart, b: ITermPart) => a.count - b.count,
      },
      render: (v: number) => {
        return (
          <span>
            {v || 0}
            <span className="dem">л.</span>
          </span>
        )
      },
    },
    {
      title: 'Залишок',
      dataIndex: 'balance',
      key: 'balance',
      width: 100,
      sorter: {
        compare: (a: ITermPart, b: ITermPart) => a.balance - b.balance,
      },
      render: (v: number) => {
        return (
          <span>
            {v || 0}
            <span className="dem">л.</span>
          </span>
        )
      },
    },
    {
      title: 'Ціна',
      dataIndex: 'price',
      key: 'price',
      width: 100,
      sorter: {
        compare: (a: ITermPart, b: ITermPart) => a.balance - b.balance,
      },
      render: (v: number) => {
        return (
          <span>
            {v.toLocaleString('ua-UA', {
              style: 'currency',
              currency: 'UAH',
            }) || 0}
          </span>
        )
      },
    },
    {
      title: 'Термін дії',
      dataIndex: 'dateEnd',
      key: 'dateEnd',
      width: 150,
      sorter: {
        compare: (a: ITermPart, b: ITermPart) => {
          const first = new Date(a.dateEnd.date).getTime()
          const second = new Date(b.dateEnd.date).getTime()
          return first - second
        },
      },
      render: (v: { date: string; term_expires: boolean; block: boolean }) => {
        const parseDate = moment(v.date).utc().format(FORMAT_DATE)
        return !v.block && v.term_expires ? (
          <Popover content={contentTermExpires}>
            <span className={'warning'}>
              {parseDate} <ExclamationOutlined />
            </span>
          </Popover>
        ) : v.block ? (
          <Popover content={contentBlockPart}>
            <span className="warning">
              Заблоковано <StopOutlined />
            </span>
          </Popover>
        ) : (
          parseDate
        )
      },
    },
    {
      title: 'Продовження терміну',
      dataIndex: 'dateEnd',
      key: 'dateEnd',
      width: 75,
      render: (v: { block: boolean }, data: ITermPart) => {
        if (v.block) {
          return (
            <span
              className="refill cursor"
              onClick={(event) => continuationPart(event, data.document)}
            >
              <ClockCircleOutlinedIcon />
            </span>
          )
        }
      },
    },
  ]
}

export const getBalanceColumns = () => {
  return [
    {
      title: 'Баланс',
      dataIndex: 'alias',
      key: 'alias',
      width: 200,
    },
    {
      title: 'Залишок',
      dataIndex: 'count',
      key: 'count',
      width: 100,
      render: (v: number) => (
        <span>
          {v}
          <span className="dem">л.</span>
        </span>
      ),
    },
  ]
}

export const getFuelCardsColumns = (
  wallets: IWalletAccounts,
  refund: Function,
  download: Function
) => {
  return [
    {
      title: 'Термін дії',
      dataIndex: 'СрокДействия',
      key: 'СрокДействия',
      width: 100,
      sorter: {
        compare: (a: FuelCards, b: FuelCards) => {
          const first = new Date(a['СрокДействия']).getTime()
          const second = new Date(b['СрокДействия']).getTime()
          return first - second
        },
      },
      render: (v: string) => moment(v).utc().format(FORMAT_DATE),
    },
    {
      title: 'Користувач',
      dataIndex: 'АккаунтНаименование',
      key: 'АккаунтНаименование',
      width: 150,
      render: (v: string) => {
        return <span>{v}</span>
      },
    },
    {
      title: 'Вид пального',
      dataIndex: 'Номенклатура',
      key: 'Номенклатура',
      width: 75,
      render: (v: string) => {
        const fuel = FUELS.find((fuel) => fuel.value === v)
        return <span>{fuel?.name}</span>
      },
    },
    {
      title: 'Партія',
      dataIndex: 'Партия',
      key: 'Партия',
      width: 200,
      render: (v: string) => {
        return <span>{v}</span>
      },
    },
    {
      title: 'Номінал',
      dataIndex: 'Номинал',
      key: 'Номинал',
      width: 75,
      sorter: {
        compare: (a: FuelCards, b: FuelCards) => a['Номинал'] - b['Номинал'],
      },
      render: (v: string) => {
        return <span>{v}</span>
      },
    },
    {
      title: 'Кількість талонів',
      dataIndex: 'КоличествоТалонов',
      key: 'КоличествоТалонов',
      width: 75,
      sorter: {
        compare: (a: FuelCards, b: FuelCards) =>
          a['Количество'] - b['Количество'],
      },
      render: (v: string) => {
        return `${v}`
      },
    },
    {
      title: "Загальний об'єм(л.)",
      dataIndex: 'Количество',
      key: 'Количество',
      width: 75,
      render: (v: string) => {
        return v
      },
    },
    {
      title: 'Використано літрів',
      dataIndex: 'КоличествоОтоварено',
      key: 'КоличествоОтоварено',
      width: 75,
      render: (v: string) => {
        return <span>{v}</span>
      },
    },

    {
      title: 'Термін дії партії',
      dataIndex: 'СрокДействияПартии',
      key: 'СрокДействияПартии',
      width: 100,
      sorter: {
        compare: (a: FuelCards, b: FuelCards) => {
          const first = new Date(a['СрокДействия']).getTime()
          const second = new Date(b['СрокДействия']).getTime()
          return first - second
        },
      },
      render: (v: string) => moment(v).utc().format(FORMAT_DATE),
    },
    {
      title: 'Статус',
      dataIndex: 'Состояние',
      key: 'Состояние',
      width: 75,
      render: (v: string) => {
        if (v === 'Заблокирован') {
          return <span className="blockedFuelCard">Заблокований</span>
        }
        if (v === 'УКлиента') {
          return <span className="usedFuelCard">У клієнта</span>
        }
      },
    },
    {
      title: '',
      dataIndex: 'Состояние',
      key: 'Состояние',
      width: 75,
      render: (v: string, data: FuelCards) => {
        if (v === 'УКлиента') {
          return (
            <span
              className="refill cursor"
              onClick={(event) => {
                event.stopPropagation()
                download(data.УИДГенерации)
              }}
            >
              <DownloadIcon />
            </span>
          )
        }
      },
    },
  ]
}

export const getFuelCardsTransactionsColumns = () => {
  return [
    {
      title: 'Дата',
      dataIndex: 'date',
      key: 'date',
      width: 200,
      render: (v: string) => moment(v).utc().format(FORMAT_DATE),
      sorter: {
        compare: (a: ITFuelCardsTransactions, b: ITFuelCardsTransactions) => {
          const first = new Date(a['date']).getTime()
          const second = new Date(b['date']).getTime()
          return first - second
        },
      },
    },
    {
      title: 'Користувач',
      dataIndex: 'userAlias',
      key: 'userAlias',
      width: 200,
    },
    {
      title: 'Вид пального',
      dataIndex: 'fuelType',
      key: 'fuelType',
      width: 100,
      render: (v: string) => {
        const fuel = FUELS.find((fuel) => fuel.value === v)
        return <span>{fuel?.name}</span>
      },
    },
    {
      title: 'К-сть літрів',
      dataIndex: 'amount',
      key: 'amount',
      width: 100,
    },
    {
      title: 'Тип транзакції',
      dataIndex: 'type',
      key: 'type',
      width: 150,
    },
    {
      title: 'АЗК',
      dataIndex: 'gasStation',
      key: 'gasStation',
    },
  ]
}
