import React from "react";

interface IMainLayout {
  children: React.Component
}

const MainLayout: React.FC<IMainLayout> = ({ children }) => {
  return <div className="wrapper">{children}</div>;
};

export default MainLayout;
