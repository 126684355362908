import React, { useState, useEffect, useContext } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { getInvoiceColumn } from '../main/columns'
import {
  Spin,
  Select,
  Row,
  Col,
  Table,
  Modal,
  Button,
  Form,
  Input,
  notification,
  DatePicker,
  Space,
} from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useWindowSize } from '../../hooks'
import { Invoice } from '../../interfaces'
import { FUELS } from '../../constants'
import { AppContext, AppContextInterface } from '../../AppProvider'
import {
  getFuelLimit,
  getInvoiceHistory,
  getInvoicePrice,
  sendInvoice,
} from '../../services/apiService'
import locale from '../../../node_modules/antd/lib/calendar/locale/uk_UA.js'
import moment from 'moment'
import { ReloadBtn } from '../../components/ReloadBtn'
import { RootState } from '../../store'
import { setLoading } from '../../store/loader/actions'
import AccountSelect from '../../components/selects/AccountSelect'
import WalletSelect from '../../components/selects/WalletSelect'

const { Option } = Select
const { RangePicker }: any = DatePicker

const Buy: React.FC<RouteComponentProps> = () => {
  const { wallet, wallets } = useContext(AppContext) as AppContextInterface
  const dispatch = useDispatch()
  const { loading } = useSelector((state: RootState) => state.loader)
  const { width } = useWindowSize()
  const fcTWidth = width && width < 575 ? width * 0.4 : 200
  const userInfo = localStorage.getItem('user')
  const contractNumber = localStorage.getItem('contractNumber')
  const [showInvoiceModal, setShowInvoiceModal] = useState<boolean>(false)
  const [invoice, setInvoice] = useState<Array<Invoice>>([])
  const [filteredInvoice, setFilteredInvoice] = useState<Array<Invoice>>([])
  const invoiceColumn: Array<any> = getInvoiceColumn(fcTWidth)
  const [invoiceFuel, setInvoiceFuel] = useState<string | undefined>()
  const [invoicePrice, setInvoicePrice] = useState<number>()
  const [fuelLimit, setFuelLimit] = useState<{
    min: number
    max: number
  }>()
  const [invoiceFuelCount, setInvoiceCount] = useState<number>()

  const isMobile = !!width && width < 480
  const [invoiceForm] = Form.useForm()

  const [filteredStatus, setFilteredStatus] = useState<string>('')
  const [invoicePeriod, setInvoicePeriod] = useState<
    [moment.Moment, moment.Moment]
  >([moment().subtract(2, 'month'), moment()])

  const [dataPickerOpen, setDataPickerOpen] = useState<boolean>(false)
  const [mail, setMail] = useState<string>(
    userInfo ? JSON.parse(userInfo).ЭлектронныйАдрес : ''
  )

  // for mobile we use two columns, the remaining columns are displayed by clicking on the expand button
  const columnsInvoiceMobile = invoiceColumn.slice(0, 2)
  const columnsInvoiceExpanded = invoiceColumn.slice(2)

  const updateInvoiseHistory = () => {
    dispatch(setLoading(true))
    const date1 = invoicePeriod
      ? invoicePeriod[0].format('YYYY-MM-DDTHH:mm:ss') + 'Z'
      : undefined
    const date2 = invoicePeriod
      ? invoicePeriod[1].format('YYYY-MM-DDTHH:mm:ss') + 'Z'
      : undefined

    setFilteredInvoice([])
    getInvoiceHistory(wallet, date1, date2)
      .then((res) => {
        dispatch(setLoading(false))
        const invoices = res.Result
        setInvoice(
          invoices.map((inv: any, index: number) => {
            return {
              key: index,
              ...inv,
            }
          })
        )
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    if (wallet && !dataPickerOpen) {
      updateInvoiseHistory()
    }
  }, [wallet, dataPickerOpen]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (wallet) {
      getInvoicePrice(wallet).then((res) => {
        const price = res.Result.filter(
          (p: any) => p['Номенклатура'] === invoiceFuel
        )
        if (price) {
          setInvoicePrice(price.map((p: any) => p['Цена'])[0])
        }
      })
    }
    if (invoiceFuel) {
      getFuelLimit(wallet, invoiceFuel).then((res) => {
        setFuelLimit({
          min: res.Result['МинимальноеКоличество'],
          max: res.Result['МаксимальноеКоличество'],
        })
      })
    }
  }, [wallet, invoiceFuel])

  useEffect(() => {
    if (filteredStatus) {
      setFilteredInvoice(
        invoice.filter((invoceItem) => invoceItem.Статус === filteredStatus)
      )
    }
  }, [filteredStatus]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!invoicePeriod && !loading) {
      updateInvoiseHistory()
      setInvoicePeriod([moment().subtract(2, 'month'), moment()])
    }
  }, [invoicePeriod]) // eslint-disable-line react-hooks/exhaustive-deps

  const InvoiceCost =
    invoicePrice && invoiceFuelCount ? invoicePrice * invoiceFuelCount : 0

  const openNotification = (type: string, message: string) => {
    if (type === 'success') {
      notification[type]({
        message,
      })
    }
  }

  const onSendInvoice = () => {
    sendInvoice(wallet, invoiceFuel, invoiceFuelCount, invoicePrice, mail).then(
      (res) => {
        if (res.Status === 0) {
          openNotification('success', res.Result['Инфо'])
          updateInvoiseHistory()
        }
      }
    )
    setShowInvoiceModal(false)
    invoiceForm.resetFields()
  }

  const openInvoiceModal = () => {
    setShowInvoiceModal(true)
  }

  const closeInvoiceModal = () => {
    setShowInvoiceModal(false)
    invoiceForm.resetFields()
    setInvoicePrice(0)
  }

  const onCountFuelInputChange = (event: any) => {
    setInvoiceCount(event.target.value)
  }

  const getWalletName = () => {
    const name = wallets.filter((item) => item.Счет === wallet)
    return name.map((item) => item.СчетНаименование)[0]
  }

  return (
    <div>
      <Row
        gutter={[16, 16]}
        align="top"
        justify="space-between"
        className="height-auto overflow-auto term"
      >
        <Col className="left">
          <AccountSelect />
        </Col>

        <Col>
          <WalletSelect />
        </Col>

        <Col>
          <div className="select-flex">
            <div className="ant-col">
              <label>Період</label>
            </div>
            <RangePicker
              locale={locale}
              onChange={setInvoicePeriod}
              inputReadOnly={true}
              value={invoicePeriod}
              getPopupContainer={(node: any) => node.parentNode as HTMLElement}
              onOpenChange={setDataPickerOpen}
            />
          </div>
        </Col>

        <Col>
          <div className="select-flex">
            <div className="ant-col">
              <label>Статус</label>
            </div>
            <Select
              className="list__wallets select-width"
              value={filteredStatus}
              onChange={(value) => {
                setFilteredStatus(value)
              }}
            >
              <Option value={''}>Всі</Option>
              <Option value={'ОжидаетсяОплата'}>Очікується оплата</Option>
              <Option value={'Просроченный'}>Прострочений</Option>
              <Option value={'Оплаченный'}>Оплачений</Option>
            </Select>
          </div>

          <div className="select-flex">
            <div className="ant-col">
              <Space size={10}>
                <ReloadBtn
                  btnText="Оновити"
                  onClickAction={() => updateInvoiseHistory()}
                />
                <Button
                  className="ant-btn-dashed reload-btn"
                  onClick={openInvoiceModal}
                >
                  + Придбати талони
                </Button>
              </Space>
            </div>
          </div>
        </Col>

        <Table
          columns={width && width < 480 ? columnsInvoiceMobile : invoiceColumn}
          locale={{
            triggerDesc: 'Сортувати за спаданням',
            triggerAsc: 'Сортувати за зростанням',
            cancelSort: 'Скасувати сортування',
          }}
          dataSource={filteredStatus.length ? filteredInvoice : invoice}
          size="small"
          pagination={{
            showSizeChanger: true,
            pageSizeOptions: ['10', '25', '50', '100'],
            defaultPageSize: 10,
          }}
          className="table-full-width term-table"
          scroll={{ x: 'max-content' }}
          expandable={
            isMobile
              ? {
                  expandedRowRender: (v) =>
                    columnsInvoiceExpanded.map((item, index) => (
                      <div className="expandable-wrap" key={index}>
                        <div>{item.title}</div>
                        <div className="green">
                          {item.render(v[item.key as keyof Invoice], v)}
                        </div>
                      </div>
                    )),
                  columnWidth: 33,
                }
              : undefined
          }
          loading={{ indicator: <Spin />, spinning: loading }}
        />
      </Row>

      {/******************** GET INVOICE MODAL ********************/}
      <Modal
        title={'Придбати талони'}
        footer={null}
        className="invoice"
        width="800"
        visible={showInvoiceModal}
        onCancel={closeInvoiceModal}
      >
        <div className="details">
          <Form form={invoiceForm} onFinish={onSendInvoice}>
            <Form.Item className="invoice-item">
              <div className="details-item">
                <span>Договір</span>
                <span>Договір №{contractNumber}</span>
              </div>
            </Form.Item>

            <Form.Item className="invoice-item">
              <div className="details-item">
                <span>Рахунок</span>
                <span>{getWalletName()}</span>
              </div>
            </Form.Item>

            <div className="details-item">
              <span>Email</span>
              <Form.Item
                className="fuel-invoice-select"
                name="mail"
                initialValue={mail}
                rules={[
                  { required: true, message: 'Введіть email' },
                  { type: 'email', message: 'Введіть коректний email' },
                ]}
              >
                <Input
                  className="fuel-select"
                  type="mail"
                  onChange={(event) => {
                    setMail(event.target.value)
                  }}
                />
              </Form.Item>
            </div>

            <div className="details-item">
              <span>Талони на </span>
              <Form.Item
                name="fuel"
                className="fuel-invoice-select"
                rules={[{ required: true, message: 'Оберіть тип пального' }]}
              >
                <Select
                  placeholder="Обрати номенклатуру"
                  onChange={setInvoiceFuel}
                  className="fuel-select"
                  value={invoiceFuel}
                  getPopupContainer={(node) => node.parentNode}
                >
                  {FUELS.map((fuel: any, i: any) => (
                    <Option value={fuel.value} key={`transfer_${i}`}>
                      {fuel.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <Form.Item
              name="count"
              className="invoice-item"
              rules={[
                { required: true, message: 'Введіть кількість пального' },
                {
                  validator: (_, value) =>
                    value === undefined ||
                    value === '' ||
                    (+value >= (fuelLimit?.min || 0) &&
                      +value <= (fuelLimit?.max || 1000))
                      ? Promise.resolve()
                      : Promise.reject(
                          `Мінімальна кількість - ${fuelLimit?.min} л, максимальна - ${fuelLimit?.max} л`
                        ),
                },
              ]}
            >
              <div className="details-item">
                <span>Кількість літрів </span>
                <span>
                  <Input
                    onChange={onCountFuelInputChange}
                    className="fuel-limit"
                    type="number"
                    min={fuelLimit?.min}
                    max={fuelLimit?.max}
                    disabled={!invoiceFuel}
                  />
                </span>
              </div>
            </Form.Item>
            <Form.Item className="invoice-item">
              <div className="details-item">
                <span>Ціна</span>
                <span>{invoicePrice || 0}</span>
              </div>
            </Form.Item>
            <Form.Item className="invoice-item">
              <div className="details-item">
                <span>Сума</span>
                <span>{InvoiceCost}</span>
              </div>
            </Form.Item>
            <Form.Item>
              <div className="invoice-submit">
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={!InvoiceCost}
                >
                  Замовити рахунок на email
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  )
}

export default Buy
