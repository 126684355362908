import React from "react";
import { Form, Button, Spin, Input } from "antd";
import { RouteComponentProps } from "react-router-dom";
import { PhoneOutlined } from "@ant-design/icons";
import InputMask from "react-input-mask";

import { ReactComponent as Logo } from "../../assets/logo.svg";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../store/loader/actions";
import { RootState } from "../../store";
import { sendPhoneNumber } from "../../services/apiService";

const LoginForm: React.FC<RouteComponentProps> = ({ history }) => {
  const [form] = Form.useForm();
  const { validateFields } = form;
  const dispatch = useDispatch();
  const { loading } = useSelector((state: RootState) => state.loader);

  const handleSubmit = () => {
    validateFields().then((values) => {
      const phone = values.telephone.replace(/\+|\(|\)| /gi, "");
      localStorage.setItem("ik:phone", phone);
      dispatch(setLoading(true));
      sendPhoneNumber(phone).then((res) => {
        dispatch(setLoading(false));
        if (!res.Error) {
          history.push("/verification");
        }
      });
    });
  };

  return (
    <Spin spinning={loading} delay={300}>
      <div className="App">
        <div className="App-container">
          <div className="App-form">
            <div className="logo">
              <Logo />
            </div>
            <Form form={form} onFinish={handleSubmit} layout="vertical">
              <Form.Item
                name="telephone"
                label="Введіть свій номер телефону :"
                rules={[
                  {
                    pattern: new RegExp("\\+380\\(\\d{2}\\) \\d{3} \\d{4}"),
                    message: "Невірний формат телефону",
                    validateTrigger: 'onSubmit'
                  },
                  { required: true, message: "Введіть дійсний номер телефону" },
                ]}
              >
                <InputMask mask="+380(99) 999 9999" onChange={(e) => { }}>
                    <Input
                      autoFocus
                      type="tel"
                      prefix={<PhoneOutlined className="site-form-item-icon" />}
                      placeholder={"+380(00)000 0000"}
                      size="large"
                    />
                </InputMask>
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" className="default-button">
                  Далі
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default LoginForm;
