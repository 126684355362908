import * as React from "react";
import { Fragment, FC } from "react";
import { RouteComponentProps, StaticContext } from "react-router";
import Title from "./Title";

interface IWithTtle {
  component: FC<RouteComponentProps<{}, StaticContext, unknown>>, 
  title: string,
  data?: Object
}

export const withTitle = ({ component: Component, title, data } : IWithTtle) => {
    return (props: any) => {
      return (
        <Fragment>
          <Title name={`eTalon - ${title}`} /> <Component {...props} {...data} />
        </Fragment>
      );
    };
  };