import { Select } from 'antd'
import React, { FC, useContext } from 'react'
import { AppContext, AppContextInterface } from '../../../AppProvider'
import { ExclamationOutlined } from '@ant-design/icons'
import { setWallet } from '../../../store/account/actions'
import { useDispatch } from 'react-redux'

const { Option } = Select

const WalletSelect: FC = () => {
  const { wallet, wallets } = useContext(AppContext) as AppContextInterface
  const dispatch = useDispatch()

  const selectWallet = (wallet: string) => {
    localStorage.setItem('wallet', wallet)
    dispatch(setWallet(wallet))
  }

  return (
    <div className="select-flex">
      <div className="ant-col">
        <label>Оберіть рахунок</label>
      </div>
      <Select
        className="list__wallets select-width"
        onSelect={selectWallet}
        value={wallet}
      >
        {wallets.map((item, index) => (
          <Option key={`wallets_${index}`} value={item['Счет']}>
            {item['СрокДействияИстикает'] ? (
              <span className="warning">
                {item['СчетНаименование']}
                <ExclamationOutlined />
              </span>
            ) : (
              item['СчетНаименование']
            )}
          </Option>
        ))}
      </Select>
    </div>
  )
}

export default WalletSelect
