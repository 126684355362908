import { Col, Divider, List, Row, Spin } from 'antd'
import React, { FC, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../store'
import AccountSelect from '../../../components/selects/AccountSelect'
import { AppContext, AppContextInterface } from '../../../AppProvider'
import { setLoading } from '../../../store/loader/actions'
import { getGetContactsForClient } from '../../../services/apiService'
import {
  MailOutlined,
  PhoneOutlined,
  ReadOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { Link } from 'react-router-dom'

interface iContactInfo {
  type: string | undefined
  value: string
}

const Contacts: FC = () => {
  const { loading } = useSelector((state: RootState) => state.loader)
  const { account } = useContext(AppContext) as AppContextInterface
  const dispatch = useDispatch()
  const [contactInfo, setContactInfo] = useState<Array<iContactInfo>>([])

  useEffect(() => {
    if (account) {
      dispatch(setLoading(true))
      getGetContactsForClient(account)
        .then((res) => {
          dispatch(setLoading(false))
          if (res.Status === 0) {
            setContactInfo(res.Result)
          }
        })
        .catch((err) => console.log(err))
    }
  }, [account]) // eslint-disable-line react-hooks/exhaustive-deps

  const iconCase = (item: iContactInfo) => {
    switch (item.type) {
      case 'email':
        return <MailOutlined />
      case 'phone':
        return (
          <span>
            <PhoneOutlined />
          </span>
        )
      case 'name':
        return (
          <span>
            <UserOutlined />
          </span>
        )
      default:
        break
    }
  }

  return (
    <Spin spinning={loading}>
      <Row
        gutter={[16, 16]}
        align="top"
        justify="space-between"
        className="height-auto overflow-auto"
      >
        <Col className="left">
          <AccountSelect />
        </Col>
      </Row>
      <Divider orientation="left">Контакти Вашого менеджера</Divider>
      <List
        bordered
        dataSource={contactInfo}
        renderItem={(item) => (
          <List.Item>
            <span className="actions">
              {iconCase(item)}
              {item.value}
            </span>
          </List.Item>
        )}
      />

      <div style={{ marginTop: '20px' }}>
        <span className="actions">
          <ReadOutlined />
          <Link
            to={{
              pathname: 'https://wog.ua/ua/kontakty-regionalnyx-predstavnyctv/',
            }}
            target="_blank"
          >
            Контакти регіональних представництв
          </Link>
        </span>
      </div>
    </Spin>
  )
}

export default Contacts
