import { Select } from 'antd'
import React, { FC, useContext } from 'react'
import { useDispatch } from 'react-redux'
import { AppContext, AppContextInterface } from '../../../AppProvider'
import { setAccount } from '../../../store/account/actions'

const { Option } = Select

const AccountSelect: FC = () => {
  const dispatch = useDispatch()
  const { accountsList, account } = useContext(
    AppContext
  ) as AppContextInterface

  const handleChangeAccount = (data: string) => {
    localStorage.removeItem('wallet')
    localStorage.setItem('account', data)
    dispatch(setAccount(data))
  }
  return (
    <div className="select-flex">
      <div className="ant-col">
        <label>Оберіть організацію</label>
      </div>
      <Select
        onChange={handleChangeAccount}
        value={account}
        className="select-width"
      >
        {accountsList.map((item, i) => (
          <Option key={`account_${i}`} value={item['Контрагент']}>
            {item['КонтрагентНаименование']}
          </Option>
        ))}
      </Select>
    </div>
  )
}

export default AccountSelect
