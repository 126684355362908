import React, { createContext, useEffect, useState } from 'react'
import { Modal } from 'antd'
import { IWallet, IAccount, IWalletAccounts } from './interfaces'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from './store'
import { setLoading } from './store/loader/actions'
import {
  getOrganizations,
  getWalletAccounts,
  getWallets,
} from './services/apiService'
import { setAccount, setUserName, setWallet } from './store/account/actions'

const emptyAccounts = {
  content:
    'Користувач має мати відкритий рахунок для користування функціоналом сайту.',
}

export interface AppContextInterface {
  accountsList: Array<IAccount>
  wallet?: string
  wallets: Array<IWallet>
  account?: string
  walletAccounts: IWalletAccounts
  getUsersData: Function
  termExpires: boolean
}

export const AppContext = createContext<Partial<AppContextInterface>>({})

export const AppProvider: React.FC<{ children: any }> = ({ children }) => {
  const dispatch = useDispatch()
  const { account } = useSelector((state: RootState) => state.account)
  const { wallet } = useSelector((state: RootState) => state.account)
  const [accountsList, setAccountsList] = useState<Array<IAccount>>([])
  const [wallets, setWallets] = useState<Array<IWallet>>([])
  const [walletAccounts, setWalletAccounts] = useState<IWalletAccounts>()
  const [termExpires, setTermExpires] = useState<boolean>(false)

  useEffect(() => {
    dispatch(setLoading(true))
    getOrganizations().then((res) => {
      dispatch(setLoading(false))
      if (res.Status === 0) {
        if (res.Result.length) {
          setAccountsList(res.Result)
          dispatch(
            setAccount(
              localStorage.getItem('account') ||
                res.Result[0]['Контрагент'] ||
                ''
            )
          )
        } else {
          Modal.info(emptyAccounts)
        }
      }
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (account) {
      const defaultWallet = localStorage.getItem('wallet')
      dispatch(setLoading(true))
      getWallets(account).then((res) => {
        if (res.Status === 0) {
          const wallet = defaultWallet
            ? res.Result.find((i: IWallet) => i['Счет'] === defaultWallet)
            : null
          setWallets(res.Result)
          dispatch(setWallet(wallet ? wallet['Счет'] : res.Result[0]['Счет']))
          localStorage.setItem('contractNumber', res.Result[0]['НомерДоговора'])

          const isExpired = !!res.Result.find(
            (wallet: IWallet) => wallet['СрокДействияИстикает']
          )
          setTermExpires(isExpired)
        }
      })
    }
  }, [account]) // eslint-disable-line react-hooks/exhaustive-deps

  const getUsersData = () => {
    dispatch(setLoading(true))
    getWalletAccounts(wallet).then((res) => {
      dispatch(setLoading(false))
      if (res.Status === 0) {
        const currentAccount =
          res.Result['Аккаунты'].filter(
            (item: any) =>
              item['АккаунтНомерТелефона'] === localStorage.getItem('ik:phone')
          )[0] || {}
        dispatch(
          setUserName(
            `${currentAccount['АккаунтФамилия']} ${currentAccount['АккаунтИмя']}`
          )
        )
        setWalletAccounts(res.Result)
      }
    })
  }

  useEffect(() => {
    if (wallet) {
      getUsersData()
    }
  }, [wallet]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AppContext.Provider
      value={{
        accountsList,
        wallet,
        wallets,
        account,
        walletAccounts,
        termExpires,
        getUsersData,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}
