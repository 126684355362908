export interface AccountState {
  account?: string,
  user_name?: string,
  wallet?: string
}

export const SET_ACCOUNT = 'SET_ACCOUNT';
export const SET_WALLET = 'SET_WALLET';
export const SET_USER_NAME = 'SET_USER_NAME';

interface IAccount {
  type: typeof SET_ACCOUNT
  payload?: string
}

interface IUserName {
  type: typeof SET_USER_NAME
  payload?: string
}

interface IWallet {
  type: typeof SET_WALLET
  payload?: string
}

export type AccountActionTypes = IAccount;
export type UserNameActionTypes = IUserName;
export type WalletActionTypes = IWallet;