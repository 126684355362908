import { Select } from 'antd'
import React, { FC, useEffect, useState } from 'react'
import { FUELS } from '../../../constants'
import { IFuels } from '../../../interfaces'

interface IFFuelSelect {
  value?: Array<string>
  onChange?: Function
  useFuelKey?: boolean
  clear?: boolean
}

const { Option } = Select

const FuelSelect: FC<IFFuelSelect> = ({
  onChange,
  useFuelKey,
  clear = false,
}) => {
  const [selectedFuel, setSelectedFuel] = useState<Array<string>>([])

  useEffect(() => {
    if (clear) {
      setSelectedFuel([])
    }
  }, [clear])

  useEffect(() => {
    if (typeof onChange === 'function') {
      onChange(selectedFuel)
    }
  }, [selectedFuel]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="select-flex">
      <div className="ant-col">
        <label>Оберіть НП</label>
      </div>

      <Select
        mode="multiple"
        maxTagCount={2}
        placeholder="Обрати номенклатуру"
        showArrow
        showSearch={false}
        className="select-width"
        value={selectedFuel}
        onChange={setSelectedFuel}
      >
        {FUELS.map((fuel: IFuels, index: number) => (
          <Option
            value={useFuelKey ? fuel.key : fuel.value}
            key={`fuel_${index}`}
          >
            {fuel.name}
          </Option>
        ))}
      </Select>
    </div>
  )
}

export default FuelSelect
