import {
  Button,
  Form,
  Input,
  Modal,
  Radio,
  Select,
  Space,
  Table,
  Tooltip,
} from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { PhoneOutlined } from '@ant-design/icons'
import InputMask from 'react-input-mask'
import { Fuel, IBalance, IPart, showMainModal } from '../../../interfaces'
import { ReactComponent as Arrow } from '../../../assets/arrow.svg'
import { ReactComponent as Info } from '../../../assets/info.svg'
import { TRANSFER_TYPE } from '..'
import { AppContext, AppContextInterface } from '../../../AppProvider'
import { getAllFuelsColumns } from '../columns'
import { EMPTY_GUID, FORMAT_DATE, FUELS, FUELS_ENUM } from '../../../constants'
import moment from 'moment'
import { useWindowSize } from '../../../hooks'
import {
  moveBalance,
  validateTransferPhoneNumber,
} from '../../../services/apiService'
import { setLoading } from '../../../store/loader/actions'
import { useDispatch } from 'react-redux'
import { Guid } from 'guid-typescript'
import openNotification from '../../../services/openNotification'

const { Option } = Select
const UNALLOCATED_BALANCE = 'Нерозподілений баланс'

const getKeyByValue = (object: { [key: string]: any }, value: string) =>
  Object.keys(object).find((key: string) => object[key] === value)
const parseDate = (date: string) => moment(date).utc().format(FORMAT_DATE)

interface IFTransferModal {
  user: IBalance | undefined
  showModal: showMainModal
  transferType: string | undefined
  walletBalance: IBalance[] | Partial<IBalance>[]
  balances: IBalance[] | Partial<IBalance>[]
  undistributedBalance: IBalance | undefined
  setShowConfirm: Function
}

const TransferModal: React.FC<IFTransferModal> = (props) => {
  const user = props.user
  const showModal = props.showModal
  const transferType = props.transferType
  const walletBalance = props.walletBalance
  const balances = props.balances
  const undistributedBalance = props.undistributedBalance
  const setShowConfirm = props.setShowConfirm

  const { wallet, wallets, getUsersData } = useContext(
    AppContext
  ) as AppContextInterface
  const dispatch = useDispatch()
  const columnsLocalStorage = localStorage.getItem('columns')
  const { width } = useWindowSize()

  const refillWallet = walletBalance[1]
  const transferFromText =
    user?.userPhone !== EMPTY_GUID ? 'З балансу' : 'З користувача'
  const allFuelsColumns: Array<any> = getAllFuelsColumns()
  const [transferForm] = Form.useForm()

  const [transferColumns, setTransferColumns] = useState<Array<any>>([])
  const [partsList, setPartsList] = useState<Array<IPart>>()
  const [fuelKey, setFuelKey] = useState<string>()
  const [transferFuel, setTransferFuel] = useState<string | undefined>()
  const [transferUser, setTransferUser] = useState<
    IBalance | Partial<IBalance>
  >()
  const [transferFuelSelectList, setTransferFuelSelectList] = useState(
    columnsLocalStorage ? JSON.parse(columnsLocalStorage || '') : []
  )
  const [transferUserType, setTransferUserType] = useState<number>(1)
  const [disableSubmitTransfer, setDisableSubmitTransfer] =
    useState<boolean>(false)

  const getColumnsWithRemains = (userBalance: IBalance) => {
    return allFuelsColumns?.filter((column) => {
      const fuelTypes = userBalance
        ? (userBalance[column.key as keyof IBalance] as Fuel)
        : null
      return fuelTypes?.count ? column : null
    })
  }

  const getRemains = (userBalance: IBalance) => {
    const remains = [
      userBalance?.a100_Mustang?.count
        ? {
            name: 'A-100 Mustang',
            key: 'a100_Mustang',
            value: FUELS_ENUM.a100_Mustang,
          }
        : null,
      userBalance?.a95_Mustang?.count
        ? {
            name: 'A-95 Mustang',
            key: 'a95_Mustang',
            value: FUELS_ENUM.a95_Mustang,
          }
        : null,
      userBalance?.a95?.count
        ? {
            name: 'A-95',
            key: 'a95',
            value: FUELS_ENUM.a95,
          }
        : null,
      userBalance?.a92?.count
        ? {
            name: 'A-92',
            key: 'a92',
            value: FUELS_ENUM.a92,
          }
        : null,
      userBalance?.dp_Mustang_Plus?.count
        ? {
            name: 'ДП Mustang+',
            key: 'dp_Mustang_Plus',
            value: FUELS_ENUM.dp_Mustang_Plus,
          }
        : null,
      userBalance?.dp?.count
        ? {
            name: 'ДП',
            key: 'dp',
            value: FUELS_ENUM.dp,
          }
        : null,
      userBalance?.gas?.count
        ? {
            name: 'Газ нафтовий',
            key: 'gas',
            value: FUELS_ENUM.gas,
          }
        : null,
    ]
    return remains.filter((remain) => remain != null)
  }

  const chooseAccount = (phone: string) => {
    if (transferType === TRANSFER_TYPE.refill) {
      setTransferUser(
        phone !== EMPTY_GUID
          ? balances.find((account) => account.userPhone === phone)
          : undistributedBalance
      )
    }
  }

  const openUsersDropdown = (open: boolean) => {
    const modal = document.getElementsByClassName('transfer-modal-wrapper')[0]
    open
      ? modal.classList.add('no-scroll')
      : modal.classList.remove('no-scroll')
  }

  const transferUserRadioGroupClickHandler = (e: any) => {
    setTransferUserType(e.target.value)
    if (e.target.value === 1) {
      setDisableSubmitTransfer(false)
    }
  }

  const hasNumberInSystem = async (_: any, phoneNumber: string) => {
    if (phoneNumber.match('\\+380\\(\\d{2}\\) \\d{3} \\d{4}')) {
      const res = await validateTransferPhoneNumber(phoneNumber)
      if (res && phoneNumber) {
        if (transferUserType === 2) {
          setDisableSubmitTransfer(true)
        }
        if (res.Status === 0) {
          setDisableSubmitTransfer(false)
          return Promise.resolve(true)
        }
        return Promise.reject(res)
      }
      setDisableSubmitTransfer(false)
      return Promise.resolve(true)
    }
    setDisableSubmitTransfer(false)
    return Promise.resolve(true)
  }

  const onViewBalance = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    event.stopPropagation()
    closeTransferModal()
  }

  const getMoveBalance = () => {
    const moveWallets = [refillWallet]
    moveWallets.concat(...balances)
    return transferType === TRANSFER_TYPE.move
      ? moveWallets.concat(...balances)
      : [undistributedBalance as IBalance]
  }

  const closeTransferModal = () => {
    setShowConfirm({ ...showModal, showTransfer: false })
    setPartsList([])
    setTransferFuel(undefined)
    setTransferUser(undefined)
    transferForm.resetFields()
    setTransferUserType(1)
  }

  const postTransfer = (values: {
    fuel: string
    fuelAmount: number
    transferAccount: string
    part: string
    otherUserPhone: string
    transferWallet: string
  }) => {
    dispatch(setLoading(true))
    const guid = localStorage.getItem('guid') || Guid.create().toString()
    if (!localStorage.getItem('guid')) {
      localStorage.setItem('guid', guid)
    }
    if (user && wallet) {
      const order = {
        phoneReciever:
          transferType === TRANSFER_TYPE.refill
            ? user.userPhone
            : values.transferAccount,
        id: guid,
        phone:
          transferType === TRANSFER_TYPE.refill
            ? values.transferAccount
            : user.userPhone,
        fuel: values.fuel,
        amount: values.fuelAmount,
        account: wallet,
        part: values.part,
        otherUserPhone: values.otherUserPhone,
        transferWallet: values.transferWallet,
      }
      moveBalance(order).then((res) => {
        dispatch(setLoading(false))
        localStorage.removeItem('guid')
        if (res.Status === 0) {
          closeTransferModal()
          getUsersData()
          const successMassage =
            transferType === TRANSFER_TYPE.refill
              ? 'Поповнення виконано успішно!'
              : 'Переказ виконано успішно!'
          openNotification('success', successMassage)
        }
      })
    }
  }

  useEffect(() => {
    if (user) {
      setTransferColumns(
        getColumnsWithRemains(user).filter((column) => column != null)
      )
      setTransferFuelSelectList(getRemains(user))
    }

    if (transferType === TRANSFER_TYPE.refill) {
      setTransferColumns(
        getColumnsWithRemains(walletBalance[1] as IBalance).filter(
          (column) => column != null
        )
      )
      setTransferFuelSelectList(getRemains(walletBalance[1] as IBalance))
    }
  }, [user, transferType]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (transferFuel) {
      setFuelKey(getKeyByValue(FUELS_ENUM, transferFuel))
    }
    if (transferType === TRANSFER_TYPE.refill) {
      const parts =
        transferFuel && transferUser
          ? transferUser[fuelKey as keyof typeof FUELS_ENUM]?.parts
          : []
      setPartsList(parts?.filter((item) => !item['Заблокирована']))
    } else {
      const parts = transferFuel
        ? user![fuelKey as keyof typeof FUELS_ENUM]?.parts
        : []
      setPartsList(parts?.filter((item) => !item['Заблокирована']))
    }
  }, [transferType, transferFuel, user, transferUser, fuelKey])

  useEffect(() => {
    showModal.showTransfer &&
      transferForm.setFieldsValue({
        part: null,
      })
  }, [transferFuel, transferUser, showModal.showTransfer]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal
      title={transferType === TRANSFER_TYPE.refill ? 'Поповнення' : 'Переказ'}
      visible={showModal.showTransfer}
      onCancel={() => {
        closeTransferModal()
      }}
      footer={null}
      className="transfer-modal"
      wrapClassName="transfer-modal-wrapper"
      width={620}
    >
      <div>
        <div className="details">
          <span className="details-item">
            <span className="header">Обраний користувач:</span>
            <span>{user?.alias || user?.accountName}</span>
          </span>
          <Table
            columns={transferColumns}
            dataSource={
              transferType === TRANSFER_TYPE.refill ? [refillWallet] : [user!]
            }
            size="small"
            pagination={false}
            className="table-full-width"
            scroll={{ x: 'max-content' }}
          />
        </div>
        <Form form={transferForm} onFinish={postTransfer} layout="vertical">
          <div>
            <span className="section">
              Вид пального <span className="hr"></span>
            </span>
            <Form.Item
              name="fuel"
              className="fuel"
              rules={[{ required: true, message: 'Оберіть тип пального' }]}
            >
              <Select
                placeholder="Обрати номенклатуру"
                onChange={setTransferFuel}
                className="fuel-select"
                value={transferFuel}
                getPopupContainer={(node) => node.parentNode}
              >
                {transferFuelSelectList.map((fuel: any, i: any) => (
                  <Option value={fuel.value} key={`transfer_${i}`}>
                    {fuel.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          {transferType !== TRANSFER_TYPE.refill && (
            <div>
              <span className="section">
                Партія <span className="hr"></span>
              </span>
              <Form.Item
                name="part"
                rules={[{ required: true, message: 'Оберіть партію' }]}
              >
                <Select
                  placeholder="Обрати партію"
                  className="fuel-select"
                  getPopupContainer={(node) => node.parentNode}
                >
                  {partsList &&
                    partsList.map((i) => {
                      return (
                        <Option
                          value={i['ДокументПартии']}
                          key={i['ДокументПартии']}
                        >
                          {`${parseDate(i['ДатаПополнения'])} - ${
                            i['Количество']
                          }`}
                          <span className="dem">л.</span>
                        </Option>
                      )
                    })}
                </Select>
              </Form.Item>
            </div>
          )}
          <Form.Item
            name="fuelAmount"
            className="amount"
            rules={[
              { required: true, message: 'Введіть кількість пального' },
              {
                validator: (_, value) =>
                  value === undefined || value === '' || +value > 0
                    ? Promise.resolve()
                    : Promise.reject('Кількість повинна бути більша за "0"'),
              },
            ]}
          >
            <div>
              <span className="section">
                Кількість <span className="hr"></span>
              </span>
              <Input type="number" min="0" step="0.01" />
            </div>
          </Form.Item>
          <Form.Item className="margin_0">
            <span className="section">
              {transferType === TRANSFER_TYPE.refill
                ? transferFromText
                : 'Отримувач'}
              <span className="hr"></span>
            </span>
            {transferType === TRANSFER_TYPE.move ? (
              <Radio.Group
                onChange={transferUserRadioGroupClickHandler}
                value={transferUserType}
              >
                <Space
                  direction={width && width < 620 ? 'vertical' : 'horizontal'}
                >
                  <Radio value={1}>користувач рахунку</Radio>
                  <Radio value={2}>
                    сторонній користувач
                    <Tooltip title="Передаючи пальне сторонньому користувачеві Ви повністю передаєте права на нього та не зможете більше керувати цими об'ємами">
                      <span className="transfer-info-icon">
                        {' '}
                        <Info />
                      </span>
                    </Tooltip>
                  </Radio>
                  {user?.accountName === UNALLOCATED_BALANCE && (
                    <Radio value={3}>рахунок організації</Radio>
                  )}
                </Space>
              </Radio.Group>
            ) : null}
          </Form.Item>
          {transferUserType === 1 && (
            <Form.Item
              name="transferAccount"
              className="account"
              rules={[
                {
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject('Оберіть рахунок'),
                },
              ]}
            >
              <Select
                onChange={chooseAccount}
                placeholder={
                  user?.userPhone !== EMPTY_GUID
                    ? 'Обрати баланс'
                    : 'Обрати користувача'
                }
                getPopupContainer={(node) => node.parentNode}
                onDropdownVisibleChange={
                  user?.userPhone === EMPTY_GUID ? openUsersDropdown : undefined
                }
              >
                {(undistributedBalance && user?.userPhone !== EMPTY_GUID
                  ? getMoveBalance()
                  : balances
                ).map((item: IBalance | Partial<IBalance>, i: any) => (
                  <Option
                    className="accounts-option"
                    key={`account_${i}`}
                    value={item.userPhone || ''}
                  >
                    <span className="header">
                      {item.accountName === UNALLOCATED_BALANCE
                        ? `${item.alias || item.accountName}`
                        : `Користувач ${item.alias || item.accountName}`}
                    </span>
                    <span className="balance">
                      <span className="item">
                        {transferFuel && (
                          <span className="amount">
                            <span className="amount__title">
                              {FUELS.find((i) => i.key === fuelKey)?.name}
                            </span>
                            {` - ${
                              item[fuelKey as keyof typeof FUELS_ENUM]?.count ||
                              0
                            }л.`}
                          </span>
                        )}
                      </span>
                      <span className="link" onClick={onViewBalance}>
                        Див. баланс <Arrow />
                      </span>
                    </span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
          {transferUserType === 2 && (
            <Form.Item
              name="otherUserPhone"
              rules={[
                {
                  required: true,
                  message: 'Введіть номер телефону',
                  validateTrigger: 'onSubmit',
                },
                {
                  validator: hasNumberInSystem,
                  message: 'Немає реєстрації телефону одержувача',
                },
                {
                  pattern: new RegExp('\\+380\\(\\d{2}\\) \\d{3} \\d{4}'),
                  message: 'Невірний формат телефону',
                  validateTrigger: 'onSubmit',
                },
              ]}
            >
              <InputMask mask="+380(99) 999 9999" onChange={(e) => {}}>
                <Input
                  type="tel"
                  prefix={<PhoneOutlined className="site-form-item-icon" />}
                  placeholder={'+380(00)000 0000'}
                />
              </InputMask>
            </Form.Item>
          )}

          {transferUserType === 3 && (
            <Form.Item
              name="transferWallet"
              className="account"
              rules={[
                {
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject('Оберіть рахунок'),
                },
              ]}
            >
              <Select placeholder="Обрати рахунок організації">
                {wallets.map(
                  (item, index) =>
                    item['Счет'] !== wallet && (
                      <Option key={`wallets_${index}`} value={item['Счет']}>
                        {item['СчетНаименование']}
                      </Option>
                    )
                )}
              </Select>
            </Form.Item>
          )}
          {transferType === TRANSFER_TYPE.refill && (
            <div>
              <span className="section">
                Партія <span className="hr"></span>
              </span>
              <Form.Item
                name="part"
                rules={[{ required: true, message: 'Оберіть партію' }]}
              >
                <Select
                  placeholder="Обрати партію"
                  className="fuel-select"
                  getPopupContainer={(node) => node.parentNode}
                >
                  {partsList &&
                    partsList.map((i: any) => {
                      return (
                        <Option
                          value={i['ДокументПартии']}
                          key={i['ДокументПартии']}
                        >
                          {`${parseDate(i['ДатаПополнения'])} - ${
                            i['Количество']
                          }`}
                          <span className="dem">л.</span>
                        </Option>
                      )
                    })}
                </Select>
              </Form.Item>
            </div>
          )}
          <Form.Item className="invoice-submit">
            <Button
              disabled={disableSubmitTransfer}
              type="primary"
              htmlType="submit"
            >
              {transferType === TRANSFER_TYPE.refill
                ? 'Поповнити'
                : 'Переказати'}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  )
}

export default TransferModal
