import { INDEX_PAGE_SIZE_DEFAULT, DEFAULT_PAGE_SIZE } from '../../constants';

import {
  PaginatorState,
  PaginatorActionTypes,
  SET_PAGING_DATA
} from './types'

const initialState: PaginatorState = {
  current: 1,
  pageSize: INDEX_PAGE_SIZE_DEFAULT,
  pageSizeOptions: DEFAULT_PAGE_SIZE,
  total: 0,
  lastPage: false
}

export function paginationReducer(
  state = initialState,
  action: PaginatorActionTypes
): PaginatorState {
  switch (action.type) {
    case SET_PAGING_DATA:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}