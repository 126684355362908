import { ArrowDownOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React, { FC } from 'react'

interface IFDownloadBtn {
  btnText?: string
  onClickAction: Function
  className?: string
}

const DownloadBtn: FC<IFDownloadBtn> = (props) => {
  const onClickAction = () => {
    props.onClickAction()
  }

  const className = props.className ? props.className : 'reload-btn'

  return (
    <Button
      className={className}
      type="dashed"
      //shape="circle"
      icon={<ArrowDownOutlined />}
      //disabled={}
      onClick={onClickAction}
    >
      {props.btnText}
    </Button>
  )
}

export default DownloadBtn
