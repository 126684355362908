export interface LoginState {
  isAuth: boolean,
  chosenAccount: boolean,
}

export const SET_AUTH_STATUS = 'SET_AUTH_STATUS';

interface ILogIn {
  type: typeof SET_AUTH_STATUS
  payload: LoginState
}

export type LoginActionTypes = ILogIn;