export interface LoaderState {
    loading: boolean
  }

export const SET_LOADING = 'SET_LOADING';

interface ILoader {
  type: typeof SET_LOADING
  payload: boolean
}

export type LoaderActionTypes = ILoader;