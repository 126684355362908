import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../store'
import { AppContext, AppContextInterface } from '../AppProvider'
import { Button } from 'antd'
import { LoadingOutlined, ReloadOutlined } from '@ant-design/icons'

interface IFReloadBtn {
  btnText?: string
  shape?: 'circle' | 'round' | undefined
  onClickAction?: Function | undefined
}

export const ReloadBtn: React.FC<IFReloadBtn> = (props) => {
  const { getUsersData } = useContext(AppContext) as AppContextInterface
  const { loading } = useSelector((state: RootState) => state.loader)

  const toggleReload = () => {
    if (props.onClickAction) {
      props.onClickAction()
    } else {
      getUsersData()
    }
  }

  return (
    <Button
      className="reload-btn"
      type="dashed"
      shape={props.shape}
      icon={loading ? <LoadingOutlined /> : <ReloadOutlined />}
      disabled={loading}
      onClick={toggleReload}
    >
      {props.btnText}
    </Button>
  )
}
