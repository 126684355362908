import { DatePicker, Modal, Table } from 'antd'
import React, { FC, useContext, useEffect, useState } from 'react'
import { FuelCards, ITFuelCardsTransactions } from '../../../interfaces'
import { AppContext, AppContextInterface } from '../../../AppProvider'
import { getFuelCardsTransactionsColumns } from '../../main/columns'
import {
  getTransactionsFuelCard,
  transactionsFuelCardReport,
} from '../../../services/apiService'
import { transactionsFuelCardAdapter } from '../../../services/adapters'
import locale from 'antd/lib/date-picker/locale/uk_UA'
import DownloadBtn from '../../../components/DownloadBtn'
import { downloadXLSX } from '../../../services/fileDownload'

interface IETalonHistoryModal {
  showModal: Array<any>
  data: FuelCards | undefined
}

const TalonHistoryModal: FC<IETalonHistoryModal> = (props) => {
  const { walletAccounts, wallet } = useContext(
    AppContext
  ) as AppContextInterface
  const [fuelCardsColumns, setFuelCardsColumns] = useState<Array<any>>([])
  const [loadingTransactions, setLoadingTransactions] = useState<boolean>(false)
  const [filteredTransactions, setFilteredTransactions] = useState<
    Array<ITFuelCardsTransactions>
  >([])
  const [dateTransactions, setDateTransactions] = useState<
    [moment.Moment, moment.Moment] | undefined
  >()
  const selectedUser = walletAccounts?.Аккаунты.find(
    (acc) => acc.Аккаунт === props.data?.Аккаунт
  )
  const { RangePicker }: any = DatePicker
  const showModal = props.showModal[0]
  const setShowModal = props.showModal[1]

  useEffect(() => {
    setFuelCardsColumns(getFuelCardsTransactionsColumns())
  }, [])

  useEffect(() => {
    if (showModal) {
      console.log('loadTransactions')
      setLoadingTransactions(true)
      const date1 = dateTransactions
        ? dateTransactions[0].format('YYYY-MM-DDTHH:mm:ss') + 'Z'
        : undefined
      const date2 = dateTransactions
        ? dateTransactions[1].format('YYYY-MM-DDTHH:mm:ss') + 'Z'
        : undefined
      getTransactionsFuelCard(
        selectedUser?.АккаунтНомерТелефона,
        wallet,
        date1,
        date2,
        props.data?.Номенклатура,
        props.data?.ДокументПартии
      )
        .then((res) => {
          if (res.Status === 0) {
            const trans = transactionsFuelCardAdapter(res.Result)
            setFilteredTransactions(trans)
          }
        })
        .finally(() => setLoadingTransactions(false))
    }
  }, [showModal, dateTransactions]) // eslint-disable-line react-hooks/exhaustive-deps

  const onChangeDate = (value: any, dateString: string[]) => {
    setDateTransactions(value)
  }

  const downloadReport = () => {
    const date1 = dateTransactions
      ? dateTransactions[0].format('YYYY-MM-DDTHH:mm:ss') + 'Z'
      : undefined
    const date2 = dateTransactions
      ? dateTransactions[1].format('YYYY-MM-DDTHH:mm:ss') + 'Z'
      : undefined

    transactionsFuelCardReport(
      selectedUser?.АккаунтНомерТелефона,
      wallet,
      date1,
      date2,
      props.data?.Номенклатура,
      props.data?.ДокументПартии
    )
      .then((res) => {
        downloadXLSX(res.Result.DOCUMENT, res.Result.FileName)
      })
      .catch((err) => console.log(err))
  }

  return (
    <Modal
      className="transactions"
      width="800"
      visible={showModal}
      footer={null}
      onCancel={() => {
        setShowModal(false)
        setDateTransactions(undefined)
      }}
      title={<h1>{selectedUser?.АккаунтНаименование}</h1>}
    >
      <div className="transaction-period">
        <RangePicker
          //className="reload-btn"
          locale={locale}
          onChange={onChangeDate}
          inputReadOnly={true}
          value={dateTransactions}
          getPopupContainer={(node: any) => node.parentNode as HTMLElement}
        />
        <DownloadBtn
          btnText="Завантажити звіт"
          onClickAction={() => downloadReport()}
          className="no-class"
        />
      </div>

      <Table
        columns={fuelCardsColumns}
        locale={{
          triggerDesc: 'Сортувати за спаданням',
          triggerAsc: 'Сортувати за зростанням',
          cancelSort: 'Скасувати сортування',
        }}
        loading={loadingTransactions}
        dataSource={filteredTransactions}
        size="small"
        pagination={{
          position: ['bottomRight'],
          showSizeChanger: false,
        }}
        rowKey={(record) => record.key}
        scroll={{ x: 650 }}
      />
    </Modal>
  )
}

export default TalonHistoryModal
