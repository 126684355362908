import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../store'
import { setLoading } from '../../../store/loader/actions'
import {
  getClientsFile,
  getClientsFileList,
} from '../../../services/apiService'
import { Divider, List, Spin } from 'antd'
import { FilePdfOutlined } from '@ant-design/icons'
import { downloadPDF } from '../../../services/fileDownload'

interface iFileList {
  fileName: string
  fileIndex: string
}

const Instructions: FC = () => {
  const dispatch = useDispatch()
  const { loading } = useSelector((state: RootState) => state.loader)
  const [fileList, setFileList] = useState<Array<iFileList>>([])

  const updateFileList = (data: Array<any>) => {
    const result: Array<iFileList> = data.map((item: any, i: number) => {
      return {
        fileName: item['ИмяФайла'],
        fileIndex: item['Идентификатор'],
      }
    })
    setFileList(result)
  }

  useEffect(() => {
    dispatch(setLoading(true))
    getClientsFileList()
      .then((res) => {
        dispatch(setLoading(false))
        if (res.Status === 0) {
          updateFileList(res.Result['Файлы'])
        }
      })
      .catch((err) => console.log(err))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const downloadFile = (fileIndex: string) => {
    dispatch(setLoading(true))
    getClientsFile(fileIndex)
      .then((res) => {
        dispatch(setLoading(false))
        if (res.Status === 0) {
          console.log(res.Result)
          downloadPDF(res.Result.DOCUMENT, res.Result.FileName)
        }
      })
      .catch((err) => console.log(err))
  }

  return (
    <Spin spinning={loading}>
      <Divider orientation="left">Інструкції:</Divider>
      <List
        bordered
        dataSource={fileList}
        renderItem={(item) => (
          <List.Item>
            <span
              className="actions cursor"
              onClick={() => downloadFile(item.fileIndex)}
            >
              <FilePdfOutlined /> {item.fileName}
            </span>
          </List.Item>
        )}
      />
    </Spin>
  )
}

export default Instructions
