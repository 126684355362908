import { TablePaginationConfig } from "antd/lib/table";

export interface PaginatorState extends TablePaginationConfig {
  pageSizeOptions?: Array<any>,
  lastPage: boolean
}

export const SET_PAGING_DATA = 'SET_PAGING_DATA';

interface IPaginator {
  type: typeof SET_PAGING_DATA
  payload: TablePaginationConfig
}

export type PaginatorActionTypes = IPaginator;