const REST_URL = '/api/Cabinet/'

const password =
  process.env.REACT_APP_ENV === 'production' ? '5JmHb:3$cf' : 'Cabinet'
interface IFetchOptions {
  headers: any
  body: any
  method: string
}

interface IFetcher {
  url: string
  data?: any
  opts?: IFetchOptions
}

interface IOptions {
  method: string
  data: any
  opts?: any
}

export class ErrorFetch extends Error {
  public response: any
  public code: string

  constructor(response: any) {
    super(response.statusText)
    this.response = response
    this.code = response.status
    Object.setPrototypeOf(this, ErrorFetch.prototype)
  }
}

export const checkStatus = (response: any, cb: Function) => {
  if (response.status >= 200 && response.status < 300) return response
  cb(response)
  throw new ErrorFetch(response)
}

const options = ({ method, data, opts }: IOptions) => {
  opts = opts || {}
  const headers = {
    ...opts.headers,
    ...(['POST', 'PUT', 'PATCH'].includes(method || opts.method)
      ? { 'Content-Type': 'application/json' }
      : {}),
    Charset: 'UTF-8',
    Authorization: `Basic ${btoa(`Cabinet:${password}`)}`,
    Language: 'uk',
    Version: 1,
    company: 'wog',
    device: 'cabinet',
  }

  return {
    ...opts,
    method,
    ...(Object.keys(headers).length > 0 ? { headers } : {}),
    ...(data || opts.body ? { body: JSON.stringify(data || opts.body) } : {}),
  }
}

const fetcher =
  (method: string) =>
  async ({ url, data, opts }: IFetcher, cb: Function) => {
    try {
      const response = await fetch(
        `${REST_URL}${url}`,
        options({ method, data, opts })
      )
      checkStatus(response, cb)
      return await response.json()
    } catch (err) {
      console.log(err)
      return await { error: true }
    }
  }
const queryMethods = {
  get: fetcher('GET'),
  post: fetcher('POST'),
  put: fetcher('PUT'),
  patch: fetcher('PATCH'),
  delete: fetcher('DELETE'),
}

export default queryMethods
