import React from "react";
import { ReactComponent as LowFuel } from "../../assets/low-fuel.svg";

const NotFound: React.FC = () => {
    return (
        <div className="not-found">
            <h1>404</h1>
            <LowFuel />
            <h2>Сторінку не знайдено</h2>
            <p>Вибачте, шукана Вами сторінка не існує.</p>
        </div>
    )
}

export default NotFound;