import { SET_ACCOUNT, AccountActionTypes, WalletActionTypes, SET_USER_NAME, SET_WALLET, UserNameActionTypes } from './types';

export function setAccount(account?: string): AccountActionTypes {
  return {
    type: SET_ACCOUNT,
    payload: account
  }
}

export function setUserName(user_name?: string): UserNameActionTypes {
  return {
    type: SET_USER_NAME,
    payload: user_name
  }
}

export function setWallet(wallet?: string): WalletActionTypes {
  return {
    type: SET_WALLET,
    payload: wallet
  }
}
