import { Col, Divider, List, Row, Spin } from 'antd'
import React, { FC, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../store'
import WalletSelect from '../../../components/selects/WalletSelect'
import AccountSelect from '../../../components/selects/AccountSelect'
import { AppContext, AppContextInterface } from '../../../AppProvider'
import { setLoading } from '../../../store/loader/actions'
import { getWalletAdmins } from '../../../services/apiService'
import { UserOutlined } from '@ant-design/icons'

const Admins: FC = () => {
  const { loading } = useSelector((state: RootState) => state.loader)
  const { wallet } = useContext(AppContext) as AppContextInterface
  const dispatch = useDispatch()
  const [accountsList, setAccountsList] = useState<Array<string>>([])

  useEffect(() => {
    if (wallet) {
      dispatch(setLoading(true))
      getWalletAdmins(wallet)
        .then((res) => {
          dispatch(setLoading(false))
          if (res.Status === 0) {
            setAccountsList(res.Result['Аккаунты'])
          }
        })
        .catch((err) => console.log(err))
    }
  }, [wallet]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Spin spinning={loading}>
      <Row
        gutter={[16, 16]}
        align="top"
        justify="space-between"
        className="height-auto overflow-auto"
      >
        <Col className="left">
          <AccountSelect />
        </Col>

        <Col>
          <WalletSelect />
        </Col>

        <Col style={{ width: '24%' }}></Col>
        <Col style={{ width: '24%' }}></Col>
      </Row>

      <Divider orientation="left">Список адміністраторів рахунку</Divider>
      <List
        bordered
        dataSource={accountsList}
        renderItem={(item) => (
          <List.Item>
            <UserOutlined /> {item}
          </List.Item>
        )}
      />
    </Spin>
  )
}

export default Admins
