import { notification } from 'antd'

const openNotification = (type: string, message: string) => {
  if (type === 'success') {
    notification[type]({
      message,
    })
  }
}

export default openNotification
