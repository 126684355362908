export const downloadXLSX = (
  fileData: string,
  fileName: string = 'eTalon-file.xlsx'
) => {
  const linkSource = `data:application/xlsx;base64,${fileData}`
  downloadFile(linkSource, fileName)
}

export const downloadPDF = (
  pdf: string,
  fileName: string = 'eTalon-file.pdf'
) => {
  const linkSource = `data:application/pdf;base64,${pdf}`
  downloadFile(linkSource, fileName)
}

const downloadFile = (linkSource: string, fileName: string) => {
  const downloadLink = document.createElement('a')

  downloadLink.href = linkSource
  downloadLink.download = fileName
  downloadLink.click()
}
