import { PhoneOutlined, UserOutlined } from '@ant-design/icons'
import { Button, Form, Input, Modal, Tooltip } from 'antd'
import React, { useContext } from 'react'
import InputMask from 'react-input-mask'
import { ReactComponent as Info } from '../../../assets/info.svg'
import { showMainModal } from '../../../interfaces'
import { RootState } from '../../../store'
import { useDispatch, useSelector } from 'react-redux'
import { setLoading } from '../../../store/loader/actions'
import openNotification from '../../../services/openNotification'
import { connectAccount } from '../../../services/apiService'
import { AppContext, AppContextInterface } from '../../../AppProvider'

interface IFCreationNewUserModal {
  showModal: showMainModal
  setShowConfirm: Function
}

const CreationNewUserModal: React.FC<IFCreationNewUserModal> = (props) => {
  const showModal = props.showModal
  const setShowConfirm = props.setShowConfirm
  const { wallet, getUsersData } = useContext(AppContext) as AppContextInterface
  const { loading } = useSelector((state: RootState) => state.loader)
  const dispatch = useDispatch()
  const [form] = Form.useForm()

  const cancelCreateUser = () => {
    form.setFieldsValue({ alias: '', telephone: '' })
    setShowConfirm({ ...showModal, showNewNumber: false })
  }

  const createUser = (values: { alias: string; telephone: string }) => {
    dispatch(setLoading(true))
    connectAccount(wallet, values).then((res) => {
      dispatch(setLoading(false))
      if (res.Status === 0) {
        openNotification(
          'success',
          'Вказаному користувачеві відправлено запрошення на приєднання. Після його підтвердження даної процедури в застосунку Вам надійде push-повідомлення'
        )
        cancelCreateUser()
        getUsersData()
      }
    })
  }

  return (
    <Modal
      title="Приєднати нового користувача"
      visible={showModal.showNewNumber}
      footer={null}
      onCancel={cancelCreateUser}
    >
      <div className="new-user-info">
        <Tooltip
          title='Даний функціонал дозволяє додати користувача
              застосунку eTalons до Вашого рахунку. Таким чином, Ви зможете управляти його залишками,
              бачити транзакції та блокувати використання пального з Вашого рахунку.
              В іншому випадку, користуйтеся функцією "Сторонньому користувачеві" при передачі пального.'
        >
          <span className="icon">
            <Info />
          </span>
          <span>Що означає приєднати користувача?</span>
        </Tooltip>
      </div>
      <Form form={form} onFinish={createUser} layout="vertical">
        <Form.Item
          name="alias"
          label="Ім'я користувача"
          rules={[{ required: true, message: "Введіть Ім'я користувача" }]}
        >
          <Input
            placeholder={"Введіть Ім'я"}
            prefix={<UserOutlined className="site-form-item-icon" />}
            name="alias"
            onChange={(e) => {}}
          />
        </Form.Item>
        <Form.Item
          name="telephone"
          label="Номер телефону користувача"
          rules={[
            {
              pattern: new RegExp('\\+380\\(\\d{2}\\) \\d{3} \\d{4}'),
              message: 'Невірний формат телефону',
            },
            {
              required: true,
              message: 'Введіть дійсний номер телефону',
            },
          ]}
        >
          <InputMask mask="+380(99) 999 9999" onChange={(e) => {}}>
            <Input
              type="tel"
              prefix={<PhoneOutlined className="site-form-item-icon" />}
              placeholder={'+380(00)000 0000'}
            />
          </InputMask>
        </Form.Item>

        <Form.Item className="justify-right">
          <Button type="primary" htmlType="submit" disabled={loading}>
            Приєднати
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default CreationNewUserModal
