import { combineReducers, createStore, applyMiddleware } from 'redux'
import { createLogger } from 'redux-logger'
import { loginReducer } from './login/reducers'
import { paginationReducer } from './pagination/reducers'
import { loaderReducer } from './loader/reducers'
import { accountReducer } from './account/reducers'

let middlewares = []

if (process.env.REACT_APP_ENV !== 'production') {
  const logger = createLogger({})
  middlewares.push(logger)
}

const rootReducer = combineReducers({
  login: loginReducer,
  pagination: paginationReducer,
  loader: loaderReducer,
  account: accountReducer,
})

export type RootState = ReturnType<typeof rootReducer>

const store = createStore(rootReducer, applyMiddleware(...middlewares))
export default store
