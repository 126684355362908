import React from 'react'
import { Form, Button, Spin, message, Input } from 'antd'
import { Link, RouteComponentProps } from 'react-router-dom'
import Cookies from 'js-cookie'

import { ReactComponent as Logo } from '../../assets/logo.svg'
import { useDispatch, useSelector } from 'react-redux'
import { setLoading } from '../../store/loader/actions'
import { RootState } from '../../store'
import { sendAuthorization, sendPhoneNumber } from '../../services/apiService'
import timer from '../../services/timer'
import { authorizeAccount } from '../../store/login/actions'

const END_TIMER = 60

const Verification: React.FC<RouteComponentProps> = ({ history }) => {
  const [form] = Form.useForm()
  const { validateFields } = form
  const dispatch = useDispatch()
  const { loading } = useSelector((state: RootState) => state.loader)
  const authorization = useSelector((state: RootState) => state.login)

  const sendOtp = () => {
    const time = timer(END_TIMER)
    if (time === END_TIMER) {
      form.setFieldsValue({
        сode: '',
      })
      dispatch(setLoading(true))
      sendPhoneNumber(localStorage.getItem('ik:phone')).then((res) => {
        dispatch(setLoading(false))
      })
    } else {
      message.warning(`Повторна відправка коду можлива через ${time} секунд`)
    }
  }

  const handleSubmit = () => {
    validateFields().then((values) => {
      dispatch(setLoading(true))
      sendAuthorization(values.code).then((res) => {
        dispatch(setLoading(false))
        if (res.Status === 0) {
          Cookies.set('token', res.Result['Токен'])
          localStorage.setItem('user', JSON.stringify(res.Result['Аккаунт']))
          dispatch(
            authorizeAccount({
              ...authorization,
              isAuth: !!res.Result['Токен'],
            })
          )
        }
      })
    })
  }

  return (
    <Spin spinning={loading} delay={300}>
      <div className="App">
        <div className="App-container">
          <div className="App-form">
            <div className="logo">
              <Logo />
            </div>
            <div className="flex full-width flex-start">
              <Link to="/" className="small-underline">
                Повернутися
              </Link>
            </div>
            <Form form={form} onFinish={handleSubmit} layout="vertical">
              <Form.Item
                name="code"
                label="Введіть OTP-пароль :"
                rules={[
                  {
                    pattern: new RegExp('\\d{4}'),
                    message: 'Код введено не повністю',
                    validateTrigger: 'onSubmit',
                  },
                  { required: true, message: 'Введіть OTP-пароль' },
                ]}
              >
                <Input
                  maxLength={4}
                  name="otp"
                  type="tel"
                  onChange={() => {}}
                  autoFocus
                  size="large"
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="default-button"
                >
                  Увійти
                </Button>
                <span
                  onClick={sendOtp}
                  className="flex full-width flex-center small-underline primary-color cursor"
                >
                  Повторно надіслати OTP-пароль
                </span>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </Spin>
  )
}

export default Verification
